
const ResultsHeader = (props) => {
    const { route, selectedMixSegment } = props;

    const getResultsTitle = () => {
        return (route.justResultBySegment && route.justResultBySegment === true)
            ? route.distanceName + ' ' + getSegment(selectedMixSegment) : route.distanceName;
    }

    const getSegment = (selectedMixSegment) => {
        if (selectedMixSegment === '') {
            return '';
        } else if (selectedMixSegment === 'mix-segments') {
            return route.mixSegment.name;
        } else {
            return route.checkpointSegments.filter(segment => segment.segmentId == selectedMixSegment)
                .map(segment => segment.name)[0];
        }
    }

    // Dynamic style based on the presence of a banner
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',  // Reduced top padding
        maBottom: '20px',
        minHeight: '5vh', // Ensure there is a minimum height
        height: route.banner ? 'auto' : '5vh', // Adjust height if no banner
    };


    return (
        <div style={containerStyle}>
            {route.banner ? (
                <div><img src={route.banner} alt="Event Banner" style={{width: '100%'}} /></div>  // Ensure image does not overflow
            ) : (
                <div><h4>{getResultsTitle()}</h4></div>  // You might want to control the font size here
            )}
        </div>
    );

}

export default ResultsHeader;
