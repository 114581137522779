import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import StatusMessages, {useMessages} from './StatusMessages';
import './OxxoForm.css'
import ReactDom from "react-dom";
import oxxoIcon from "../../../images/oxxo-icon-1.svg";

const {REACT_APP_BASE_URL} = process.env;

const OxxoForm = (props) => {

    const {registerAmount, distanceName, isOpenOxxoForm, hideModalOxxoForm} = props;

    const stripe = useStripe();
    const elements = useElements();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [messages, addMessage] = useMessages();


    const handleSubmit = async (e) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        e.preventDefault();
        hideModalOxxoForm();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            addMessage('Stripe.js has not yet loaded.');
            return;
        }

        const {error: backendError, client_secret: clientSecret} = await fetch(
            REACT_APP_BASE_URL + 'stripe/create-payment-intent',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    paymentMethodType: 'oxxo',
                    currency: 'mxn',
                    description: distanceName,
                    amount: registerAmount
                }),
            }
        ).then((r) => r.json());

        if (backendError) {
            addMessage(backendError.message);
            return;
        }

        addMessage('Client secret returned');

        const {error: stripeError, paymentIntent} = await stripe.confirmOxxoPayment(
            clientSecret,
            {
                payment_method: {
                    billing_details: {
                        name,
                        email,
                    },
                },
            }
        );

        if (stripeError) {
            // Show error to your customer (e.g., insufficient funds)
            addMessage(stripeError.message);
            return;
        }

        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);

        // When passing {any_prefix}succeed_immediately@{any_suffix}
        // as the email address in the billing details, the payment
        // intent will succeed after 3 seconds. We set this timeout
        // to refetch the payment intent.
        const i = setInterval(async () => {
            const {error: e, paymentIntent} = await stripe.retrievePaymentIntent(
                clientSecret
            );
            addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
            if (paymentIntent.status === 'succeeded') {
                clearInterval(i);
                hideModalOxxoForm();
            }
            if (e) {
                addMessage(e.message);
            }
        }, 500);
    };





    if(!isOpenOxxoForm) return null;

    return  ReactDom.createPortal(
        <div className='payment-form-overlay'>
            <div className='payment-form-container'>
                <div className='oxxo-payment-form-title'>
                    <button className='payment-form-close-button' onClick={hideModalOxxoForm}>X</button>
                    <img
                        src="https://cdn.pixabay.com/photo/2020/01/11/13/22/woman-4757533_960_720.jpg"
                        alt='race picture' />
                    <img src={oxxoIcon} alt='oxxo icon' style={{marginLeft: 5}}/>
                    <h5>Inscripción</h5>
                    <h4>{distanceName}</h4>
                    <h5>Costo {(registerAmount/100).toFixed(2)} MNX</h5>
                </div>
                <form id="payment-form" onSubmit={handleSubmit}>
                    <label htmlFor="name">Nombre</label>
                    <input
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />

                    <label htmlFor="email">Email</label>
                    <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button type="submit">Pagar en Oxxo</button>
                </form>

                <StatusMessages messages={messages} />
            </div>
        </div>,
        document.getElementById('checkout-oxxo-portal')
    )
}

export default withRouter(OxxoForm);