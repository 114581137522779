import React from "react";
import './LoadingSpinner.css'

const LoadingSpinner = () =>{

    return(
        <div className='loading'>
        </div>

    )

}

export default LoadingSpinner