import React from "react";
import './CompetitorTrackerPopup.css';


const CompetitorTrackerPopup = (props) =>{

    let estimatedSpeed;
    let estimatedPass
    let competitorTrackerFullName = (Object.entries(props.competitorTracker).length ===0)?0.1:props.competitorTracker[0].fullName;

    let competitorTrackerNumber = (Object.entries(props.competitorTracker).length ===0)?0.1:props.competitorTracker[0].number;

    let accumulatedDistance = (Object.entries(props.competitorTracker).length ===0)?0.1:(props.competitorTracker[0].accumulatedDistance/1000).toFixed(2);

    let estimatedPassObject = (Object.entries(props.competitorTracker).length ===0)?0.1:(props.competitorTracker[0].currentPass);

    if(typeof estimatedPassObject === 'number' && estimatedPassObject < 50) {
        estimatedPass = estimatedPassObject.toFixed(2)
        estimatedSpeed = (Object.entries(props.competitorTracker).length ===0)
                    ?0.1:(60/(props.competitorTracker[0].currentPass)).toFixed(2)
    } else {
        estimatedPass = '-';
        estimatedSpeed = '-';
    }

    let estimatedDistanceToFinish = (props.distanceTrack.length ===0 || Object.entries(props.competitorTracker).length ===0 )
        ?0.1:((props.distanceTrack[props.distanceTrack.length-1].accumulatedDistance - props.competitorTracker[0].accumulatedDistance)/1000).toFixed(2);

    // let timeToFinish = (estimatedDistanceToFinish*60/estimatedSpeed).toFixed(1);

    let timeToNextCheckpoint = (Object.entries(props.competitorTracker).length ===0)?0.1:(props.competitorTracker[0].timeToNextCheckpoint);

    let nextCheckpoint = (Object.entries(props.competitorTracker).length ===0)?0.1:(props.competitorTracker[0].nextCheckpoint);


    return (

        <div className='tracker-popup-rapper'>
            <h6 className = 'tracker-list-title'>{competitorTrackerFullName} {competitorTrackerNumber}</h6>
            <ul className='tracker-ulist'>
                <li className= 'tracker-ilist'>
                    <span className='tracker-list-item'>Distancia recorrida </span>
                    <span className='tracker-data-item'>{accumulatedDistance} km</span>
                </li>
                <li className= 'tracker-ilist'>
                    <span className='tracker-list-item'>Distancia  para finalizar</span>
                    <span className='tracker-data-item'>{estimatedDistanceToFinish} km</span>
                </li>
                <li className= 'tracker-ilist'>
                    <span className='tracker-list-item'>Paso estimado</span>
                    <span className='tracker-data-item'>{estimatedPass} min/km</span>
                </li>
                <li className= 'tracker-ilist'>
                    <span className='tracker-list-item'>Velocidad estimada</span>
                    <span className='tracker-data-item'>{estimatedSpeed} km/h</span>
                </li>
                <li className= 'tracker-ilist'>
                    <span className='tracker-list-item'>Tiempo para {nextCheckpoint} :</span>
                    <span className='tracker-data-item'>{timeToNextCheckpoint}</span>
                </li>
            </ul>
            <div className='button-div'>
                <button onClick={()=> props.stopCompetitorTracking(competitorTrackerNumber)}>
                    Detener monitoreo
                </button>
            </div>
        </div>

    )
}

export default CompetitorTrackerPopup;