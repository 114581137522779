import React, {createContext, useState} from "react";


export const ShowLinksContext = createContext();


const ShowLinksContextProvider = (props) =>{

    const [showLinks, setShowLinks] = useState(false)

    const showLinksOnClick = ()=>{
        setShowLinks(!showLinks)
    }

    const value = {showLinks,showLinksOnClick}

    return (
        <ShowLinksContext.Provider value={value}>
            {props.children}
        </ShowLinksContext.Provider>
    )
}

export default ShowLinksContextProvider;