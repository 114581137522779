// import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import Sidebar from "../../react-leaflet-side-tabs/SideBar";
import Tab from "../../react-leaflet-side-tabs/Tab";
import {FiChevronLeft, FiCompass} from "react-icons/fi";
import {BiRun} from 'react-icons/bi'
import TrackingAllTable from "./TrackingAllTable";
import CompetitorDetailTable from "./CompetitorDetailTable";

const TrackingAllSideBar = ({   map,
                                competitorsToShow,
                                competitorsTracking,
                                distance, getInRaceTime,
                                getTimeInterval,
                                filterByGender,
                                search,
                                selectedGender,
                                handleGenderSelection,
                                handleRowClick,
                                selectedRowData,
                                openTab,
                                onClose,
                                onOpen}) => {


    return (
        <div  >
            <Sidebar
                map={map}
                position="left"
                collapsed={!openTab}
                selected={openTab}
                closeIcon={<FiChevronLeft />}
                onClose={onClose}
                onOpen={onOpen}
                panMapOnChange
                rehomeControls>

                <Tab id="home" header={distance.distanceName} icon={<BiRun />} active>
                    <TrackingAllTable competitorsTracking = {competitorsTracking}
                                      competitorsToShow={competitorsToShow}
                                      distance = {distance}
                                      onOpen = {onOpen}
                                      getInRaceTime={getInRaceTime}
                                      getTimeInterval={getTimeInterval}
                                      filterByGender={filterByGender}
                                      search={search}
                                      selectedGender={selectedGender}
                                      handleGenderSelection={handleGenderSelection}
                                      handleRowClick={handleRowClick}
                                      // sidebarHeight={sidebarHeight}
                    />
                </Tab>

                <Tab id="competidor" header={selectedRowData &&`${selectedRowData.number} ${selectedRowData.fullName}`} icon={<FiCompass />}>

                    <CompetitorDetailTable  selectedRowData = {selectedRowData}
                                            distance = {distance}
                    />

                </Tab>

            </Sidebar>
        </div>
    )

}

export default TrackingAllSideBar

