import React, {useEffect, useMemo, useState} from "react";
import {GeoJSON, LayersControl, MapContainer, TileLayer, ZoomControl} from "react-leaflet";
import {indexOf} from "leaflet/src/core/Util";
import './TrackingAllMap.css'
import CompetitorTrackingAllMarker from "./CompetitorTrackingAllMarker";
import CheckpointsMarkers from "./CheckpointMarkers";
import LoadingSpinner from "../../layout/LoadingSpinner";

const TrackingAllMap = (props) =>{

    const {lineString, competitorsToShow, distance, selectedGender, trackingNumber, track} = props;

    if (track.length === 0) {
        return <LoadingSpinner/>
    } else {
        // const map = useMemo(() => {

            return (
                <div className="mapAllContainer">
                    <MapContainer
                        center={[track[0].trackPointLatitude, track[0].trackPointLongitude]}
                        zoom={14}
                        scrollWheelZoom={true}
                        maxZoom={20}
                        doubleClickZoom={false}
                        whenCreated={props.setMap}
                        zoomControl={false}>
                        <LayersControl position="topleft">
                            <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="Esri.WorldImagery">
                                <TileLayer
                                    attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                                    url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                                />
                            </LayersControl.BaseLayer>
                        </LayersControl>
                        <ZoomControl position='topleft'/>
                        <GeoJSON
                            key={(Object.entries(lineString).length === 0) ? 1 : indexOf(lineString.geometry.coordinates)}
                            data={lineString.geometry}
                            style={{color: "#f35744"}}/>
                        <CompetitorTrackingAllMarker
                            competitorsToShow={competitorsToShow}
                            selectedGender={selectedGender}
                        />
                        <CheckpointsMarkers
                            distance={distance}
                            trackingNumber={trackingNumber}
                        />
                    </MapContainer>
                </div>

            )
        // }, [competitorsToShow])

        // return map;
    }

}

export default TrackingAllMap