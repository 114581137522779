import {Tabs} from "@feuer/react-tabs";
import React, {useContext, useEffect, useRef, useState} from "react";
import ResultsTable from "./ResultsTable";
import Navbar from "../../layout/Navbar";
import './ResultsTabs.css';
import {ShowLinksContext} from "../../../context/ShowLinksContext";
import ResultsHeader from "./ResultsHeader";

const ResultsTabs = (props) => {
    const {route} = props.location.state;

    const [topMargin, setTopMargin] = useState([]);

    const wrapperRef = useRef(null);

    const {showLinks} = useContext(ShowLinksContext);

    useEffect(()=>{

        const { current } = wrapperRef;
        const getMarginTop = ()=>{

            let nodeStyle = window.getComputedStyle(current);
            let topMargin = nodeStyle.getPropertyValue('margin-top');
            setTopMargin(topMargin);

        }
        getMarginTop();

    }, [])


    const toggleTopMargin = () =>{
        let newMarginTop;
        let marginTopNumber = parseInt(topMargin[0]);

        if(showLinks && marginTopNumber< 50){
            newMarginTop = marginTopNumber+175;

            return {marginTop : newMarginTop}
        }
    }

    return (
        <div>
            <Navbar/>
            <ResultsHeader
                route={route}
                selectedMixSegment={" "}/>
            <div className='tabs-container' ref={wrapperRef} style={toggleTopMargin()}>
            <Tabs
                tabsProps={{
                    style: {
                        textAlign: "left",
                    }
                }}
                activeTab={{
                    id: "tab1"
                }}

            >
{/*                <Tabs.Tab id="tab1" title="GENERAL">*/}
{/*                    <div><ResultsTable*/}
{/*                        route = {route}*/}
{/*                        branch = ""/>*/}
{/*                    </div>*/}
{/*                </Tabs.Tab>*/}
{/*                <Tabs.Tab id="tab2" title="MASCULINO">*/}
{/*                    <div><ResultsTable*/}
{/*                        route = {route}*/}
{/*                        branch = "MALE"/>*/}
{/*                    </div>*/}
{/*                </Tabs.Tab>*/}
{/*                <Tabs.Tab id="tab3" title="FEMENINO">*/}
{/*                    <div><ResultsTable*/}
{/*                        route = {route}*/}
{/*                        branch = "FEMALE"/>*/}
{/*                    </div>*/}
{/*                </Tabs.Tab>*/}
{/*            </Tabs>*/}
{/*            </div>*/}
{/*        </div>*/}
{/*    );*/}
{/*};*/}
                {/* Always show the GENERAL tab */}
                <Tabs.Tab id="tab1" title="GENERAL">
                    <div><ResultsTable route={route} branch="" /></div>
                </Tabs.Tab>

                {/* Conditionally render the MASCULINO and FEMENINO tabs */}
                {route.hideResultByGender !== true && (
                    <>
                        <Tabs.Tab id="tab2" title="MASCULINO">
                            <div><ResultsTable route={route} branch="MALE" /></div>
                        </Tabs.Tab>
                        <Tabs.Tab id="tab3" title="FEMENINO">
                            <div><ResultsTable route={route} branch="FEMALE" /></div>
                        </Tabs.Tab>
                    </>
                )}
            </Tabs>
            </div>
        </div>
    );
};


export default ResultsTabs;
