import React, {useEffect} from "react";
import {useHistory} from "react-router";
import L, {DomUtil} from "leaflet";
import {useLeafletContext} from "@react-leaflet/core";


const TrackingMapBackButton = () =>{
    const history = useHistory();
    const context = useLeafletContext()

    L.Control.TrackingMapBackButton = L.Control.extend({

        onAdd: (map) => {

            const buttonRapper = DomUtil.create('div', 'button-rapper');

            const backButton = document.createElement('button');
            backButton.classList.add('back-button');
            backButton.textContent = '<  Regresar';
            backButton.addEventListener('click', ()=>history.push('/races'));

            buttonRapper.appendChild(backButton);

            return buttonRapper;

        },
        onRemove: (map) => {}
    });

    useEffect(()=>{
        L.control.trackingMapBackButton = (opts) =>{
            return new L.Control.TrackingMapBackButton(opts);
        }
        const container = context.layerContainer || context.map;
        const control = L.control.trackingMapBackButton({position: 'bottomleft'});
        container.addControl(control);

        return ()=>{
            container.removeControl(control)
        }

    })

    return null;
}

export default TrackingMapBackButton