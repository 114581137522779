import dotenv from 'dotenv';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {createContext, useEffect, useState} from "react";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import ResultsTabs from "./components/pages/results/ResultsTabs";
import CompetitorTrackerDashboard from './components/pages/tracking/CompetitorTrackerDashboard'
import Races from "./components/pages/races/Races";
import CompetitorSeekerDashboard from "./components/pages/Seeker/CompetitorSeekerDashboard";
import Home from "./components/pages/home/Home";
import ShowLinksContextProvider from "./context/ShowLinksContext";
import CompetitionRegistration from "./components/pages/registrations/CompetitionRegistration";
import CompetitorSeekerDashboardByParams from "./components/pages/seekerByParams/CompetitorSeekerDashboardByParams";
import TrackingAllDashboard from "./components/pages/tracking-all-panel/TrackingAllDashboard";
import RacesContextProvider from "./context/RacesContextProvider";
import ResultsByParams from "./components/pages/results/ResultsByParams";
import CompetitorTrackerByParams from "./components/pages/tracking/CompetitorTrackerByParams";
import TrackingAllByParams from "./components/pages/tracking-all-panel/TrackingAllByParams";

dotenv.config({ path: `.env.${process.env.NODE_ENV}` });

function App() {

    return (
            <Router>
                <Switch>

                    <ShowLinksContextProvider >
                        <RacesContextProvider>

                            <Route path="/" exact component={Home}/>
                            <Route path="/races" component={Races} />
                            <Route path="/registrations" component={CompetitionRegistration}/>
                            <Route path="/tracking" component={CompetitorTrackerDashboard}/>
                            <Route path="/seeker" component={CompetitorSeekerDashboard}/>
                            <Route path="/tracking-all" component={TrackingAllDashboard}/>
                            <Route path="/results" component={ResultsTabs}/>
                            <Route path="/distance/:distanceParam" exact component={CompetitorSeekerDashboardByParams}/>
                            <Route path="/results-distance/:distanceParam" exact component={ResultsByParams}/>
                            <Route path="/tracker-distance/:distanceParam" exact component={CompetitorTrackerByParams}/>
                            <Route path="/track-all-distance/:distanceParam" exact component={TrackingAllByParams}/>

                        </RacesContextProvider>
                    </ShowLinksContextProvider>

                </Switch>
            </Router>
    );
}

export default App;
