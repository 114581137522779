import React, {useState} from "react";
import {Marker, Popup} from "react-leaflet";
import L from "leaflet";
import CheckpointMarkerPopup from "./CheckpointMarkerPopup";
import axios from "axios";

const CheckpointsMarkers = (props) =>{

    const {REACT_APP_BASE_URL} = process.env;

    const [numberOfCompetitorsChecked, setNumberOfCompetitorsChecked] = useState(null);
    const [checkpointTimestamps, setCheckpointTimestamps] = useState([]);
    const [loading, setLoading] = useState(true)

    const {checkpoints, numberOfCompetitors, distanceName} = props;

    const fetchFirstAndLastTimestampByCheckpoint = (distance, checkpointId) =>{

        axios.get(REACT_APP_BASE_URL + 'checkpoint-first-last-timestamps/' + distance + '/' + checkpointId)
            .then(response => {
                setCheckpointTimestamps(response.data);
                setLoading(false);

            }).catch(err=>{
            console.log(err);
        });

    }

    const fetchNumberOfCompetitorsChecked = (distance, checkpointId) =>{
        axios.get(REACT_APP_BASE_URL + "competitors-checked/"+ distance + "/" + checkpointId)
            .then(response => {
                setNumberOfCompetitorsChecked(response.data);


            }).catch(err=>{
            console.log(err);
        });
    };

    const getCheckpointIcon = (_iconSize) =>{
        return L.icon({
            iconUrl: "/icons/clock-512.png",
            iconSize : [_iconSize]

        });
    }

    return (
        checkpoints
            .filter(checkpoint => checkpoint.checkpointType !== ("PreCheck"))
            .map(checkpoint => (
                <Marker
                    key={checkpoint.checkpointId}
                    position={[checkpoint.checkpointLatitude, checkpoint.checkpointLongitude]}
                    icon={getCheckpointIcon(20)}
                    eventHandlers={{
                        click:(e)=>{
                            fetchNumberOfCompetitorsChecked(distanceName, checkpoint.checkpointId);
                            fetchFirstAndLastTimestampByCheckpoint(distanceName, checkpoint.checkpointId);
                        }}}
                    >
                    <Popup>
                        <CheckpointMarkerPopup
                            checkpoint={checkpoint}
                            numberOfCompetitors={numberOfCompetitors}
                            numberOfCompetitorsChecked={numberOfCompetitorsChecked}
                            checkpointTimestamps={checkpointTimestamps}
                            loading={loading}/>
                    </Popup>
                </Marker>
            )))
};

export default CheckpointsMarkers;