import React from 'react';

const FooterComponent = () => {
    const style = {
        backgroundColor: "#008058",
        borderTop: "1px solid #E7E7E7",
        textAlign: "left",
        color: "white",
        padding: "20px",
        position: "relative",
        left: "0",
        bottom: "0",
        height: "70px",


    }

    const phantom = {
        display: 'block',
        padding: '20px',
        height: '60px',


    }
    return (
            <div>
                <div style={phantom} />
                <div style={style}>
                <p>Upasport TM - All Rights Reserved</p>
            </div>
        </div>
    );
};

export default FooterComponent;
