import React, {useEffect, useState} from "react";
import axios from "axios";
import {GeoJSON, LayersControl, MapContainer, Pane, TileLayer, ZoomControl} from "react-leaflet";
import "leaflet/dist/leaflet.css"
import './CompetitorSeekerDashboard.css'
import {indexOf} from "leaflet/src/core/Util";
import CheckpointsMarkers from "../tracking/CheckpointsMarkers";
import CompetitorsLocationMarkers from "./CompetitorsLocationMarkers";
import SeekerMapTitle from "./SeekerMapTitle";
import SeekerMapSearchBox from "./SeekerMapSearchBox";
import SeekerMapBackButton from "./SeekerMapBackButton";
import LoadingSpinner from "../../layout/LoadingSpinner";

const CompetitorSeekerDashboard = (props) =>{

    const {REACT_APP_BASE_URL} = process.env;

    const {distanceObj} = props.location.state

    const [competitors, setCompetitors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [checkpoints,setCheckpoints] = useState([]);
    const [distanceTrack, setDistanceTrack] = useState([]);
    const [lineString,setLineString] = useState({});




    useEffect(() =>{

        axios.get(REACT_APP_BASE_URL + "competitors/" + distanceObj.distanceName)
            .then(res=>{
                setCompetitors(res.data);
                setLoading(false)

            })
            .catch(err=>{
                console.log(err);
            });
        setDistanceTrack(distanceObj.distanceTrack);
        setLineString(getLineString(distanceObj.distanceTrack));
        setCheckpoints(distanceObj.checkpoints.filter(checkpoint => checkpoint.checkpointId !== 8))

    },[])

    const getLineString = (trackPath) =>{

        let coordinates = trackPath.map((tp) => [
            tp.trackPointLongitude,
            tp.trackPointLatitude,
        ]);
        return {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: coordinates
            },
            properties: {}
        };
    }

    if (loading) return <LoadingSpinner/>

    return(
        <div className='seeker-dashboard-container'>
            <MapContainer
                center={[distanceTrack[0].trackPointLatitude, distanceTrack[0].trackPointLongitude]}
                zoom={14}
                scrollWheelZoom={true}
                maxZoom={20}
                doubleClickZoom={false}
                zoomControl={false}>
                <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Esri.WorldImagery">
                        <TileLayer
                            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        />
                    </LayersControl.BaseLayer>
                </LayersControl>
                <GeoJSON
                    key={(Object.entries(lineString).length===0)?1:indexOf(lineString.geometry.coordinates)}
                    data={lineString.geometry}
                    style={{color:"#f35744"}}
                />
                <ZoomControl position='bottomright'/>
                <CheckpointsMarkers
                    checkpoints = {checkpoints}
                    numberOfCompetitors={competitors.length}
                    distanceName={distanceObj.distanceName}
                />
                <CompetitorsLocationMarkers
                    distanceName={distanceObj.distanceName}
                />
                {/*<MapUPSLogo/>*/}
                <SeekerMapTitle
                    distanceName={distanceObj.distanceName}/>
                <SeekerMapSearchBox
                    competitors={competitors}
                />
                <SeekerMapBackButton/>
            </MapContainer>
        </div>
    )
}

export default CompetitorSeekerDashboard;