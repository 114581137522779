import React, {useEffect, useState} from "react";
import Table from "react-bootstrap/Table";
import {format, parseISO} from "date-fns";
import es from "date-fns/locale/es";
import './CompetitorDetailTable.css'


const CompetitorDetailTable = (props) => {
    const {distance, selectedRowData} = props;
    const [competitorDetails, setCompetitorDetails] = useState([])

    useEffect(() => {
        if (selectedRowData) {
            const routeCheckpoints = distance.checkpoints
                .filter(checkpoint => checkpoint.checkpointId !== 8)
                .sort((a, b) => a.checkpointId - b.checkpointId);
            const checkedCheckpoints = selectedRowData.checkedCheckpoints
                .filter(checkpoint => checkpoint.checkpointId !== 8)
                .sort((a, b) => a.checkpointId - b.checkpointId);

            const competitorDetailsArray = routeCheckpoints.map((checkpoint) => {
                const detail = {
                    checkpoint: getCheckpointLabel(checkpoint),
                    hour: "",
                    split: "",
                    total: "",
                };

                const checkedCheckpoint = checkedCheckpoints.find(
                    (checked) => checked.checkpointId === checkpoint.checkpointId
                );

                if (checkedCheckpoint) {
                    detail.hour = formatDate(checkedCheckpoint.competitorTimestamp);

                    const index = checkedCheckpoints.indexOf(checkedCheckpoint);
                    if (index !== 0) {
                        const prevCheckpoint = checkedCheckpoints[index - 1];
                        const splitTime = new Date(Date.parse(checkedCheckpoint.competitorTimestamp) -
                             Date.parse(prevCheckpoint.competitorTimestamp));
                        detail.split = msToTime(splitTime);
                    }

                    if (index !== 0) {
                        const startTime = new Date(selectedRowData.startTime);
                        const totalTime = new Date(checkedCheckpoint.competitorTimestamp) - startTime;
                        detail.total = msToTime(totalTime);
                    }
                }

                return detail;
            });

// competitorDetailsArray contains objects for each checkpoint in routeCheckpoints,
// with additional information only for those that also appear in checkedCheckpoints.



            setCompetitorDetails(competitorDetailsArray)

        }
    }, [selectedRowData])


    const getCheckpointLabel = current =>{
        return `${current.checkpointName} ${current.checkpointDescription}`
    }

    const formatDate = (isoDate) => {
        if (isoDate) {
            let date = parseISO(isoDate.toString());
            let dayName = format(date, "ccc", {locale: es});
            let hour = format(date, "HH:mm:ss", {locale: es});
            return `${dayName} ${hour}`;
        }
        return "";
    }

    const msToTime = (s) => {

        let ms, secs, mins, hrs;

        if(selectedRowData.startTime){
            // Pad to 2 or 3 digits, default is 2
            function pad(n, z) {
                z = z || 2;
                return ('00' + n).slice(-z);
            }
            ms = s % 1000;
            s = (s - ms) / 1000;
            secs = s % 60;
            s = (s - secs) / 60;
            mins = s % 60;
            hrs = (s - mins) / 60;
            return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) ;

        }else return 'n/a';
    }

    return (
        // <div className='competitor-detail-table-wrapper'>
        <>
            <Table striped >
                <thead>
                <tr>
                    <th>Checkpoint</th>
                    <th>Hora</th>
                    <th>Split</th>
                    <th>Total</th>
                </tr>
                </thead>
                <tbody>
                {competitorDetails &&
                competitorDetails.map((detail, index) =>
                    <tr key={index}>
                        <td>{detail.checkpoint}</td>
                        <td>{detail.hour}</td>
                        <td>{detail.split}</td>
                        <td>{detail.total}</td>
                    </tr>
                )}
                </tbody>
            </Table>
        </>
    )
}
export default CompetitorDetailTable;