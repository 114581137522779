import React from 'react';
import './VoucherContentToPrint.css';
import {format} from 'date-fns';
import {es} from 'date-fns/locale'
import upasportLogo from '../../../images/upa-sport-5-text-to-path.svg'

export class VoucherContentToPrint extends React.PureComponent {
    constructor(props) {
        super(props);

    }

    render() {

        if(!this.props.registerData) return null;
        return (
            <div className='voucher-content-container' >
                <div>
                    <img src={upasportLogo}/>
                </div>
                <div className='voucher-title'>
                    <h3>COMPROBANTE DE INSCRIPCIÓN</h3>
                </div>
                <div>
                    <p>HOLA <strong>{(this.props.registerData.firstName + ' ' + this.props.registerData.lastName).toUpperCase()}</strong>, este es tu comprobante de inscripci&oacute;n a la
                        competencia <strong>{this.props.distanceName.toUpperCase()}</strong></p>
                    <p><strong>{format(new Date(this.props.registerData.etd.slice(0,10)), 'dd-MMM-yyyy',{ locale: es }).toString().toUpperCase()}</strong></p>
                    <p>HORA DE SALIDA: <strong>{format(new Date(this.props.registerData.etd),"hh:mm aaaaa'm'")}</strong></p>
                </div>
                <div>
                    <p>CATEGOR&Iacute;A: {(this.props.registerData.gender ==='MALE')?'MASCULINO':'FEMENINO'}</p>
                    <p>N&Uacute;MERO DE CORREDOR: {this.props.registerData.competitorNumber}</p>
                    <p>ID DEL PAGO: {this.props.registerData.paymentId}</p>
                    <p>CORREO ELECTRÓNICO: {this.props.registerData.email}</p>
                    <p>CODIGO BEACON: {this.props.registerData.paymentId.toLowerCase().slice(-6)}</p>
                </div>
                <div className='voucher-right-content'>
                    <p>DEBES RECOGER TU <strong>KIT DE COMPETENCIA</strong>:</p>
                    <p>LUGAR: Huasca, frente al mercado.</p>
                    <p>FECHA:10-NOV-2021</p>
                    <p>HORA: 18:00</p>
                    {/*<p>&nbsp;</p>*/}
                </div>
                <div>
                    <p>RECUERDA TENER EN CUENTA:</p>
                    <ol>
                        <li>Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words,
                            consectetur, from a Lorem IpsumVDS
                        </li>
                        <li>ed the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "</li>
                        <li>Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cice</li>
                        <li>th a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.
                        </li>
                    </ol>
                    {/*<p>&nbsp;</p>*/}
                </div>
                <div>
                    <p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                        printer took a galley of type and scrambled</p>
                </div>
            </div>
        );
    }
}

export default VoucherContentToPrint;