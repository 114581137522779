import React, {useRef, useState} from "react";
import CardCheckoutForm from "./CardCheckoutForm";
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import Navbar from "../../layout/Navbar";
import OxxoForm from "./OxxoForm";
import './CompetitionRegistration.css'
import FooterComponent from "../../layout/Footer";
import stripeIcon from '../../../images/stripe-icon1.svg'
import bankCardsIcon from '../../../images/bank-cards-icon1.svg'
import oxxoIcon from '../../../images/oxxo-icon-1.svg'
import {useFormik, Formik, Form} from "formik";
import * as Yup from 'yup';
import {useHistory} from "react-router";
import {TiArrowBack} from 'react-icons/ti'

const stripePromise = loadStripe('pk_test_51IunDrFcn8XhB5Sgmycuqhdke1EQKC3VzNtEwIspPsLXjQZcRKEuHJ6o74a0IlLWFnQ0n8UtGzCplEmesGLYlPuv00d9DEhpJE');

const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    birthday: '',
    nationality: '',
    departureTime: '',
    paymentMethod: ''
};



const validationSchema = Yup.object({
    firstName: Yup.string().required('Requerido'),
    lastName: Yup.string().required('Requerido'),
    email: Yup.string().email('Formato invalido').required('Requerido'),
    gender: Yup.string().required('Requerido'),
    birthday: Yup.date().required('Requerido'),
    nationality: Yup.string().required('Requerido'),
    departureTime: Yup.string().required('Requerido'),
    paymentMethod: Yup.string().required('Reqerido')

});

const CompetitionRegistration = (props) =>{
    const {distanceObj, racePicture, raceIdDec} = props.location.state;

    const [isOpenOxxoForm, setIsOpenOxxoForm] = useState(false);
    const [isOpenCardForm, setIsOpenCardForm] = useState(false);
    const [formData, setFormData] = useState(null);

    const history = useHistory();

    const showModalOxxoForm = (event) => {
        // event.preventDefault();
        setIsOpenOxxoForm(true);
    };
    const hideModalOxxoForm = () => {
        setIsOpenOxxoForm(false);
    };

    const showModalCardForm = () => {
        // event.preventDefault(); formik warning
        setIsOpenCardForm(true);
    };
    const hideModalCardForm = () => {
        setIsOpenCardForm(false);

    };


    const onSubmit = (values, onSubmitProps) =>{
        console.log('Form data',values);
        if(values.paymentMethod ==='card'){
            setFormData(values);
            showModalCardForm();
            // onSubmitProps.resetForm();
        }else if(values.paymentMethod ==='oxxo'){
            setIsOpenOxxoForm(true);
            showModalOxxoForm();
            // onSubmitProps.resetForm();
        }

    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    console.log('Formik values', formik.values)

    return(
        <>
            <Navbar/>
            <div className='competition-registration-container'>
                <div className='competition-registration-1block'>
                    <div className='registration-title'><h5>Haga su inscripción</h5></div>
                    <h5>Información del competidor</h5>
                    <div className='registration-form-container'>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='registration-form-control'>
                            <label htmlFor='firstName'>Nombre</label>
                            <button
                                onClick={()=>history.push('/races')}
                                className='registration-form-back-button'
                            >
                                <TiArrowBack/>regresar
                            </button>
                            <input
                                type='text'
                                id='firstName'
                                name='firstName'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstName}
                            />
                            {formik.touched.firstName && formik.errors.firstName ? <div className='registration-error'>{formik.errors.firstName}</div>:null}
                        </div>
                        <div className='registration-form-control'>
                            <label htmlFor='lastName'>Apellido</label>
                            <input
                                type='text'
                                id='lastName'
                                name='lastName'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}
                            />
                        </div>
                        {formik.touched.lastName && formik.errors.lastName ? <div className='registration-error'>{formik.errors.lastName}</div>:null}
                        <div className='registration-form-control'>
                            <label htmlFor='email'>Email</label>
                            <input
                                type='email'
                                id='email'
                                name='email'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        {formik.touched.email && formik.errors.email ? <div className='registration-error'>{formik.errors.email}</div>:null}
                        <div className='registration-form-control' >
                            <label htmlFor='gender'>Sexo</label>
                        </div>
                        <div className='registration-form-control'>
                            <input
                                type='radio'
                                id='gender'
                                name='gender'
                                value='MALE'
                                style={{verticalAlign:'middle'}}
                                onChange={()=> formik.setFieldValue('gender', 'MALE')}

                            />
                            <label htmlFor='male' style={{marginLeft: 5, marginRight:35}}>Masculino</label>
                            <input
                                type='radio'
                                id='gender'
                                name='gender'
                                value='FEMALE'
                                style={{verticalAlign:'middle'}}
                                onChange={()=> formik.setFieldValue('gender', 'FEMALE')}
                            />
                            <label htmlFor='FEMALE' style={{marginLeft: 5}}>Femenino</label>
                        </div>
                        <div className='registration-form-control'>
                            <label htmlFor='birthday'>Fecha de nacimiento</label>
                            <input
                                type='date'
                                id='birthday'
                                name='birthday'
                                onChange={formik.handleChange}
                                value={formik.values.birthday}
                            />
                            {formik.touched.gender && formik.errors.gender ? <div className='registration-error'>{formik.errors.gender}</div>:null}
                        </div>
                        <div className='registration-form-control'>
                            <label htmlFor='nationality'>Nacionalidad</label>
                            <select
                                name='nationality'
                                id='nationality'
                                value={formik.values.nationality}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <option value=''>Nacionalidad</option>
                                <option value='mexicana'>mexicana</option>
                                <option value='venezolana'>venezolana</option>
                                <option value='argentina'>argentina</option>
                                <option value='española'>española</option>
                            </select>
                        </div>
                        {formik.touched.nationality && formik.errors.nationality ? <div className='registration-error'>{formik.errors.nationality}</div>:null}
                        <div className='registration-form-control'><label htmlFor='departureTime'>Seleccionar hora de salida</label>
                        <input
                            type='time'
                            id='departureTime'
                            name='departureTime'
                            onChange={formik.handleChange}
                            value={formik.values.departureTime}
                        />
                        </div>
                        {formik.touched.departureTime && formik.errors.departureTime ? <div className='registration-error'>{formik.errors.departureTime}</div>:null}
                        <div className='pay-select'>
                            <h5>Selecione su forma de pago:</h5>
                            <input
                                type='radio'
                                id='card'
                                name='paymentMethod'
                                value='card'
                                style={{verticalAlign:'middle'}}
                                onChange={()=> formik.setFieldValue('paymentMethod', 'card')}

                            />
                            <label htmlFor='card'><img src={bankCardsIcon} alt='cards icon'style={{marginLeft: 5, marginRight:55}}/></label>
                            <input
                                type='radio'
                                id='oxxo'
                                name='paymentMethod'
                                value='oxxo'
                                style={{verticalAlign:'middle'}}
                                onChange={()=> formik.setFieldValue('paymentMethod', 'oxxo')}

                            />
                            <label htmlFor='oxxo'><img src={oxxoIcon} alt='cards icon' style={{marginLeft: 5}} /></label>
                        </div>
                        <div className='submit-pay'>
                            <input
                                type="submit"
                                value={`Pagar ${(distanceObj.registerAmount/100).toFixed(2)} MNX`}
                                disabled={formik.isSubmitting || !formik.isValid}
                                />
                        </div>
                    </form>
                    </div>
                    <img src={stripeIcon} alt='stripe icon'/>
                </div>
                <div className='competition-registration-2block'>
                    <h5>Resumen</h5>
                    <div className='summarize'>
                        <img
                            src="https://cdn.pixabay.com/photo/2020/01/11/13/22/woman-4757533_960_720.jpg"
                            alt='race picture'
                        />
                        <h5>{distanceObj.distanceName}</h5>
                        <h5>Inscripción</h5>
                        <div className='registration-total'><h4>Total: {(distanceObj.registerAmount/100).toFixed(2)} MNX</h4></div>
                    </div>
                </div>
                <Elements stripe={stripePromise}>
                    <CardCheckoutForm racePicture={racePicture}      //modal
                                      distanceName={distanceObj.distanceName}
                                      registerAmount={distanceObj.registerAmount}
                                      isOpenCardForm={isOpenCardForm}
                                      hideModalCardForm={hideModalCardForm}
                                      formData={formData}
                                      raceIdDec={raceIdDec}
                                      distanceStartDate={distanceObj.distanceStartDateTime.slice(0,10)}
                    />
                    <div>
                    <OxxoForm registerAmount={distanceObj.registerAmount}     //modal
                              distanceName={distanceObj.distanceName}
                              isOpenOxxoForm={isOpenOxxoForm}
                              hideModalOxxoForm={hideModalOxxoForm}
                    />
                    </div>
                </Elements>
            </div>
            <FooterComponent/>
        </>

    );
}

export default CompetitionRegistration;