import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "../../layout/LoadingSpinner";
import TrackingAllMap from "./TrackingAllMap";
import TrackingAllSideBar from "./TrackingAllSideBar";


const TrackingAllByParams = () => {
    const {REACT_APP_BASE_URL} = process.env
    const {distanceParam} = useParams();

    const [distanceObj, setDistanceObj] = useState(null)
    const [track, setTrack] = useState([])
    const [lineString, setLineString] = useState({})
    const [map, setMap] = useState(null)
    const [competitorsTracking, setCompetitorsTracking] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [selectedGender, setSelectedGender] = useState("");
    const [competitorsToShow, setCompetitorsToShow] = useState(competitorsTracking
        .filter(competitor => (selectedGender !== "")?competitor.gender === selectedGender:competitor))
    const [trackingNumber, setTrackingNumber] = useState(competitorsTracking.length)
    const [selectedRowData, setSelectedRowData] = useState(null);

    const ALERT =  "Disculpe!!. Estamos experimentando un problema con la transmisión de datos," +
        " vuelva intentarlo en unos minutos";

    const url = `${REACT_APP_BASE_URL}/competitor_tracker/${distanceParam}`

    useEffect(() => {

        axios.get(`${REACT_APP_BASE_URL}/${distanceParam}`)
            .then(res=> {
                setDistanceObj(res.data)
                setLineString(getLineString(res.data.distanceTrack));
                setTrack(res.data.distanceTrack)
                setIsLoading(false)
            })
            .catch(err=>{
                setIsLoading(false)
                console.log(err);
            });
    },[]);

    useEffect(()=>{
        let competitorsTrackerEvent = null;
        let eventInterval = null;

        competitorsTrackerEvent =  new EventSource( url);
        competitorsTrackerEvent.onmessage =  e => setCompetitorsTracking(JSON.parse(e.data));
        if (isLoading) {
            setIsLoading(false)
        }

        eventInterval = setInterval(()=>{
            competitorsTrackerEvent.close();
            competitorsTrackerEvent =  new EventSource(url);
            competitorsTrackerEvent.onmessage =  e => setCompetitorsTracking(JSON.parse(e.data));
            console.log(`Open eventSourceInterval`);
            competitorsTrackerEvent.onerror = () =>{
                console.log("Ey there is an error");
                alert(ALERT)

                competitorsTrackerEvent.close();
                setCompetitorsTracking([]);

                clearInterval(eventInterval);
            }
        },840000);

        competitorsTrackerEvent.onerror = () =>{
            console.log("Ey there is an error");
            alert(ALERT)

            competitorsTrackerEvent.close();
            setCompetitorsTracking([]);
            clearInterval(eventInterval);
        }

        return ()=>{
            clearInterval(eventInterval);
            if(!!competitorsTrackerEvent){
                competitorsTrackerEvent.close();
            }
        }
    },[]);

    const getLineString = (trackPath) =>{

        let coordinates = trackPath.map((tp) => [
            tp.trackPointLongitude,
            tp.trackPointLatitude,
        ]);
        return {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: coordinates
            },
            properties: {}
        };
    }

    const search = (rows, searchQuery)=>{

        return rows.filter(row =>
            row.fullName.toLowerCase().indexOf(searchQuery) > -1 ||
            row.number.toString().toLowerCase().indexOf(searchQuery) > -1
        );
    }

    const getInRaceTime = (isoDate) => {
        const now = new Date();
        const isoDateInMs = Date.parse(isoDate);
        const diffInMs = now.getTime() - isoDateInMs;
        const diffInSec = diffInMs / 1000;
        const hours = Math.floor(diffInSec / 3600);
        const minutes = Math.floor((diffInSec % 3600) / 60);
        const seconds = Math.floor(diffInSec % 60);
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
    }

    const getTimeInterval = (finishTime, startTime) => {
        const finishTimeInMs = Date.parse(finishTime)
        const startTimeInMs = Date.parse(startTime)
        const  diffInMs = finishTimeInMs - startTimeInMs
        const diffInSec = diffInMs / 1000;
        const hours = Math.floor(diffInSec / 3600);
        const minutes = Math.floor((diffInSec % 3600) / 60);
        const seconds = Math.floor(diffInSec % 60);
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`

    }

    const filterByGender = (competitorsTracking, gender) => {
        if (gender !== "") {
            return competitorsTracking.filter(competitor => competitor.gender === gender)
        }else {
            return competitorsTracking
        }
    }

    const handleGenderSelection = (gender) => {
        setSelectedGender(gender);
    };

    useEffect(() =>{
        setCompetitorsToShow(competitorsTracking.filter(competitor => (selectedGender !== "")?competitor.gender === selectedGender:competitor))
    }, [selectedGender, competitorsTracking])

    useEffect(() =>{
        setTrackingNumber(competitorsTracking.length)
    }, [competitorsTracking])

    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData);
        setOpenTab('competidor')
        console.log(`RowData: ${JSON.stringify(rowData)}`)
    }

    const [openTab, setOpenTab] = useState('home')

    const onClose = () => {
        setOpenTab(false)
    }

    const onOpen = id => {
        setOpenTab(id)
    }

    if (isLoading) return <LoadingSpinner/>

    return (
        <>
            {map && <TrackingAllSideBar
                map = {map}
                competitorsTracking = {competitorsTracking}
                competitorsToShow={competitorsToShow}
                distance = {distanceObj}
                getInRaceTime={getInRaceTime}
                getTimeInterval={getTimeInterval}
                filterByGender={filterByGender}
                search={search}
                selectedGender={selectedGender}
                handleGenderSelection={handleGenderSelection}
                handleRowClick={handleRowClick}
                selectedRowData={selectedRowData}
                openTab={openTab}
                onClose={onClose}
                onOpen={onOpen}
            />}
            <TrackingAllMap
                lineString = {lineString}
                competitorsToShow={competitorsToShow}
                distance={distanceObj}
                setMap={setMap}
                selectedGender={selectedGender}
                trackingNumber={trackingNumber}
                track={track}

            />

        </>
    )
}

export default TrackingAllByParams