import React, {useContext} from "react";
import './Navbar.css'
import {ShowLinksContext} from "../../context/ShowLinksContext";
import upasportLogo from "../../images/upa-sport-5-text-to-path.svg"





const Navbar = ()=> {

    // const [showLinks, setShowLinks] = useState(false);

    const {showLinks,showLinksOnClick} = useContext(ShowLinksContext)



    return (
        <div className='Navbar'>
            <div className='logo-container'>
            <a href='/'>
                <img
                    alt="ups-logo"
                    src={upasportLogo}
                    // width="85"
                    // height="120"
                    // className="d-inline-block align-top"
                />
            </a>
            </div>
            <div className='links' id={showLinks?'hidden' : ''}>

            <a href='/'>Inicio</a>
            <a href='races'>Competencias</a>
            <a href='/'>Acerca de</a>
            </div>
            <div>
                   <button onClick={showLinksOnClick}>
                    <i className="fa fa-bars fa-1x" aria-hidden="true"/>
                </button>
            </div>
        </div>
    )

}

export default Navbar
