import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import './CompetitorsLocationMarkersPopup.css'
import CheckpointsModal from "../../modals/CheckpointsModal";
import {useMap, useMapEvents} from "react-leaflet";

const CompetitorsLocationMarkersPopup = (props) => {

    const {competitorFullName, competitorNumber, scanTimestamp, startTime, finishTime, distanceOnTrack, elevationGainOnTrack,
        longitude, latitude, checkedCheckpoints} = props;
    const [isOpen, setIsOpen] = useState(false);
    const [coords, setCoords] = useState({})


    useMapEvents({
        click() {
            hideModal();
        }
    });

    const showModal = () => {
        setIsOpen(true);
    };
    const hideModal = () => {
        setIsOpen(false);
    };

    const lastScanElapsedTimeInMin = scanTimestamp?((new Date (Date.now() -
        Date.parse(scanTimestamp.slice(0, -5))))/60000).toFixed(1):"n/a";

    const startHour = startTime?startTime.split('T')[1]:'n/a';

    const timeFromCompetitionInit = startTime?((new Date(Date.now()-
        Date.parse(startTime)))/60000).toFixed(1):'n/a';


    const googleMapRef = "http://www.google.com/maps?q=" + latitude + "," + longitude;


    return(

        <div>
            <div className='location-popup-title'>{competitorFullName}  {competitorNumber}</div>
            <div className='location-popup-body'>
                <div className='location-popup-details'>
                    <div>
                        Última medición
                    </div>
                    <div>
                        {lastScanElapsedTimeInMin} min
                    </div>
                </div>
                <div className='location-popup-details' id='div-link'>
                    <a className="link" target="_blank" rel="noopener"
                       href={googleMapRef} >
                        Ir a la localización
                        ({latitude.toFixed(4)}, {longitude.toFixed(4)})
                    </a>
                </div>
                <div className='location-popup-details'>
                    <div>
                        Tiempo desde el inicio
                    </div>
                    <div>
                        {(finishTime === null)?timeFromCompetitionInit +" min":"Finalizado"}
                    </div>
                </div>
                <div className='location-popup-details'>
                    <div>
                        Distancia en track
                    </div>
                    <div>
                        {(distanceOnTrack != null)?(distanceOnTrack/1000).toFixed(2):'n/a'} km
                    </div>
                </div>
                <div className='location-popup-details'>
                    <div>
                        Elevación Acumulada
                    </div>
                    <div>
                        {(elevationGainOnTrack != null)?elevationGainOnTrack.toFixed(2):'n/a'} m
                    </div>
                </div>
                <div className='div-checkpoints-button'>
                    <button className='checkpoints-button' onClick={e=>{
                        if(window.screen.width > 650){
                            const rect = e.target.getBoundingClientRect();
                            setCoords({
                                left: rect.x + rect.width - 300,
                                top: rect.y + window.scrollY -130
                            });
                        }

                        showModal();
                    }}
                    >checkpoints</button>
                </div>
            </div>
            <CheckpointsModal
                coords={coords}
                isOpen={isOpen}
                hideModal={hideModal}
                competitorFullName={competitorFullName}
                competitorNumber={competitorNumber}
                checkedCheckpoints={checkedCheckpoints}
                startHour={startHour}
                starTime={startTime}
            />
        </div>

    )

}

export default CompetitorsLocationMarkersPopup;