import React, {useEffect} from "react";
import L, {DomUtil} from "leaflet";
import {useLeafletContext} from "@react-leaflet/core";


const TrackingMapTitle = (props) =>{

    const {distanceName} = props;

    const htmlBody = `<h3>${distanceName}</h3>
                       <p>Panel de seguimiento predictivo de competitores</p>`

    const context = useLeafletContext()

    L.Control.TrackingMapTitle = L.Control.extend({
        onAdd: (map) => {
            const mapTitleDiv = DomUtil.create("div", "map-title");
            mapTitleDiv.classList.add('map-title-rapper')

            mapTitleDiv.innerHTML = htmlBody;
            return mapTitleDiv;
        },
        onRemove: (map) => {},
    });

    useEffect(()=>{
        L.control.TrackingMapTitle = (opts) =>{
            return new L.Control.TrackingMapTitle(opts);
        }

        const container = context.layerContainer || context.map;
        const control =L.control.TrackingMapTitle({ position: 'topleft'});
        container.addControl(control)

        return ()=>{

            container.removeControl(control)
        }
    })

    // L.control.SeekerMapTitleByParams = (opts) =>{
    //     return new L.Control.SeekerMapTitleByParams(opts);
    // }

    // L.control.SeekerMapTitleByParams({ position: 'topleft'}).addTo(map);

    return null;
}

export default TrackingMapTitle