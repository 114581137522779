import React, {useContext} from "react";
import './Home.css'
import Logo  from '../../../images/upsp-white-logo.png'
import {RiTreasureMapLine} from 'react-icons/ri'
import {GiSattelite} from 'react-icons/gi'
import {FiMonitor} from 'react-icons/fi'
import {RiMapPinTimeLine} from 'react-icons/ri'
import {Link} from "react-router-dom";
import {RacesContext} from "../../../context/RacesContextProvider";

const Home = ()=>{
    const { races, loading, error} = useContext(RacesContext)

    return (

        <div className= 'hero-rapper'>
            <div className='banner-rapper'>
                <div className='banner-navbar'>
                        <a href='/'>Inicio</a>
                        {/*<a href='/races'>Competencias</a>*/}
                        <Link to={{
                            pathname: "/races",
                            state: {
                                races: races,
                                loading: loading,
                                error: error
                            }
                        }}>
                            <span>Competencias</span>
                        </Link>
                        <a href='/'>Acerca de</a>

                </div>
                <div>
                    <p>Especialistas en  Monitoreo de Competencias Outdoor</p>
                </div>
                <div className='logo-wrapper'>
                    <img src={Logo} />
                </div>
            </div>
            <div className='widgets-rapper'>
                <div><RiMapPinTimeLine size='32px'/><br/>Cronometraje y Geolocalización</div>
                <div><FiMonitor size='32px'/><br/>Panel de monitoreo</div>
                <div><RiTreasureMapLine size='32px' rem/><br/>Seguimiento predictivo en tiempo real</div>
                <div><GiSattelite size='32px'/><br/>Red de satélites iridium</div>
            </div>
        </div>

    )

}

export default Home