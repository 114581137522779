import React from "react";
import './CheckpointsTable.css'
import {format, parseISO} from "date-fns";
import es from "date-fns/locale/es";

const CheckpointsTable = (props) => {

    const {competitorFullName, competitorNumber, checkedCheckpoints, startHour, startTime} = props

    const msToTime = (s) => {

        let ms, secs, mins, hrs;

        if(startTime){
            // Pad to 2 or 3 digits, default is 2
            function pad(n, z) {
                z = z || 2;
                return ('00' + n).slice(-z);
            }
            ms = s % 1000;
            s = (s - ms) / 1000;
            secs = s % 60;
            s = (s - secs) / 60;
            mins = s % 60;
            hrs = (s - mins) / 60;
            return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);

        }else return 'n/a';
    }

    const formatDate = (isoDate) => {
        if (isoDate) {
            let date = parseISO(isoDate.toString());
            let dayName = format(date, "ccc", {locale: es});
            let hour = format(date, "HH:mm:ss.SSS", {locale: es});
            return `${dayName} ${hour}`;
        }
        return "";
    }

    const getCheckpointLabel = current =>{
        return `${current.checkpointName} ${current.checkpointDescription}`
    }

    return (
        <div className='checkpoints-table-wrapper'>
            <h4>{competitorFullName + ' ' + competitorNumber}</h4>
            <table className='checkpoints-table'>
                <thead>
                <tr>
                    <th>Checkpoint</th>
                    <th>Hora</th>
                    <th>Split</th>
                    <th>Total</th>
                </tr>
                </thead>
                <tbody>
                {
                    checkedCheckpoints
                        .filter(checkpoint => checkpoint.checkpointId !== 8)
                        .map((checkpoint, index, array) =>
                            <tr key={checkpoint.checkpointId}>
                                <td data-label='Checkpoint'>{getCheckpointLabel(checkpoint)}</td>
                                <td data-label='Hora'>{formatDate(checkpoint.competitorTimestamp)}</td>
                                <td data-label='Split'>
                                    {index !== 0
                                        ? msToTime(
                                            new Date(
                                                Date.parse(checkpoint.competitorTimestamp) -
                                                Date.parse(array[index - 1].competitorTimestamp)
                                            )
                                        )
                                        : ''
                                    }
                                </td>
                                <td data-label='Total'>
                                    {index === 0?"":msToTime(
                                        new Date(
                                            Date.parse(checkpoint.competitorTimestamp) -
                                            Date.parse(startTime)
                                        )
                                    )}
                                </td>
                            </tr>
                        )
                }

                </tbody>
            </table>
        </div>
    )

}

export default CheckpointsTable;