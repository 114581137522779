import React from "react";
import {useMap} from "react-leaflet";
import L, {DomUtil} from "leaflet";
import './SeekerMapTiltleByParams.css'

const SeekerMapTitleByParams = (props) =>{
    const map = useMap();
    const {distanceName} = props;

    const htmlBody = `<h3>${distanceName}</h3>
                       <p>Panel de ubicación de competitores</p>`


    L.Control.MapDistanceTitle = L.Control.extend({
        onAdd: (map) => {
            const mapTitleDiv = DomUtil.create("div", "map-title");
            mapTitleDiv.classList.add('map-title-rapper')

            mapTitleDiv.innerHTML = htmlBody;
            return mapTitleDiv;
        },
        onRemove: (map) => {},
    });

    L.control.MapDistanceTitle = (opts) =>{
        return new L.Control.MapDistanceTitle(opts);
    }

    L.control.MapDistanceTitle({ position: 'topleft'}).addTo(map);

    return null;

}

export default SeekerMapTitleByParams;