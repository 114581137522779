import {useParams} from "react-router-dom";
import {Tabs} from "@feuer/react-tabs";
import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import axios from "axios";
import {ShowLinksContext} from "../../../context/ShowLinksContext";
import Navbar from "../../layout/Navbar";
import ResultsTable from "./ResultsTable";
import LoadingSpinner from "../../layout/LoadingSpinner";
import './ResultsTabs.css';
import ResultsHeader from "./ResultsHeader";

const ResultsByParams = ()  => {

    const {REACT_APP_BASE_URL} = process.env;
    const {distanceParam} = useParams();
    const {showLinks} = useContext(ShowLinksContext);
    const wrapperRef = useRef(null);

    const [isLoading, setIsLoading] = useState(true);
    const [route, setRoute] = useState(null)
    const [topMargin, setTopMargin] = useState([]);



    useLayoutEffect(() => {
        const { current } = wrapperRef;

        if (current) {
            console.log('Current is not null')
            const getMarginTop = () => {
                let nodeStyle = window.getComputedStyle(current);
                let topMargin = nodeStyle.getPropertyValue('margin-top');
                setTopMargin(topMargin);
            };

            getMarginTop();
        } else {
            console.log('Current is null')
        }
    }, [isLoading]);


    useEffect(() => {

        axios.get(`${REACT_APP_BASE_URL}/${distanceParam}`)
            .then(res=> {
                setRoute(res.data)
                setIsLoading(false)
            })
            .catch(err=>{
                setIsLoading(false)
                console.log(err);
            });
    },[]);

    const toggleTopMargin = () =>{
        let newMarginTop;
        let marginTopNumber = parseInt(topMargin[0]);

        if(showLinks && marginTopNumber < 50){
            newMarginTop = marginTopNumber + 175;

            return {marginTop : newMarginTop}
        }
    }

    if (isLoading) {

        return <LoadingSpinner/>
    }

    return (
        <div>
            <Navbar/>
            <ResultsHeader
                route={route}
                selectedMixSegment={" "}/>
            <div className='tabs-container' ref={wrapperRef} style={toggleTopMargin()}>
                <Tabs
                    tabsProps={{
                        style: {
                            textAlign: "left",
                        }
                    }}
                    activeTab={{
                        id: "tab1"
                    }}

                >
{/*                    <Tabs.Tab id="tab1" title="GENERAL">*/}
{/*                        <div><ResultsTable*/}
{/*                            route = {route}*/}
{/*                            branch = ""/>*/}
{/*                        </div>*/}
{/*                    </Tabs.Tab>*/}
{/*                    <Tabs.Tab id="tab2" title="MASCULINO">*/}
{/*                        <div><ResultsTable*/}
{/*                            route = {route}*/}
{/*                            branch = "MALE"/>*/}
{/*                        </div>*/}
{/*                    </Tabs.Tab>*/}
{/*                    <Tabs.Tab id="tab3" title="FEMENINO">*/}
{/*                        <div><ResultsTable*/}
{/*                            route = {route}*/}
{/*                            branch = "FEMALE"/>*/}
{/*                        </div>*/}
{/*                    </Tabs.Tab>*/}
{/*                </Tabs>*/}
{/*            </div>*/}
{/*        </div>*/}
{/*    );*/}
{/*}*/}
                    {/* Always show the GENERAL tab */}
                    <Tabs.Tab id="tab1" title="GENERAL">
                        <div><ResultsTable route={route} branch="" /></div>
                    </Tabs.Tab>

                    {/* Conditionally render the MASCULINO and FEMENINO tabs */}
                    {route.hideResultByGender !== true && (
                        <>
                            <Tabs.Tab id="tab2" title="MASCULINO">
                                <div><ResultsTable route={route} branch="MALE" /></div>
                            </Tabs.Tab>
                            <Tabs.Tab id="tab3" title="FEMENINO">
                                <div><ResultsTable route={route} branch="FEMALE" /></div>
                            </Tabs.Tab>
                        </>
                    )}
                </Tabs>
            </div>
        </div>
    );
};

export default ResultsByParams