import React from "react";
import ReactDom from 'react-dom'
import './CheckpointsModal.css'
import CheckpointsTable from "./CheckpointsTable";


const CheckpointsModal = (props) =>{

    const {isOpen, hideModal, competitorFullName, competitorNumber, checkedCheckpoints, startHour, starTime, coords} = props;

    if(!isOpen) return null;

    return ReactDom.createPortal(
        <div className='checkpoints-modal-container' style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }} >
            <button className='checkpoints-modal-x-button' onClick={hideModal}>X</button>
            <CheckpointsTable
                checkedCheckpoints={checkedCheckpoints}
                competitorFullName={competitorFullName}
                competitorNumber={competitorNumber}
                startHour={startHour}
                startTime={starTime}
            />
        </div>,
        document.getElementById("checkpoints-portal")
    )
}

export default CheckpointsModal