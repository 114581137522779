import {GeoJSON, LayersControl, MapContainer, Marker, TileLayer, Popup, Tooltip, ZoomControl} from "react-leaflet";
import {indexOf} from "leaflet/src/core/Util";
import L from "leaflet";
import "leaflet/dist/leaflet.css"
import Container from "react-bootstrap/Container";
import {Row, Spinner} from "react-bootstrap";
import CompetitorTrackerPopup from "./CompetitorTrackerPopup";
import './TrackingMap.css'
import CheckpointsMarkers from "./CheckpointsMarkers";
import React from "react";
import TrackingMapSearchBox from "./TrackingMapSearchBox";
import TrackingMapTitle from "./TrackingMapTitle";
import TrackingMapBackButton from "./TrackingMapBackButton";
import {getCompetitorMapIcon} from "../../../commonFuntions";

const TrackingMap = (props) =>{

    if(props.distanceTrack[0] === undefined) return <Container><Row><Spinner className="spinner" animation="border"/></Row></Container>

    return(
        <div className="mapContainer" >
            <MapContainer
                center={[props.distanceTrack[0].trackPointLatitude, props.distanceTrack[0].trackPointLongitude]}
                zoom={14}
                scrollWheelZoom={true}
                maxZoom={20}
                doubleClickZoom={false}
                zoomControl={false}>
                <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Esri.WorldImagery">
                        <TileLayer
                            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        />
                    </LayersControl.BaseLayer>
                </LayersControl>
                <ZoomControl position='bottomright'/>
                <TrackingMapTitle
                    distanceName={props.distanceName}/>
                <TrackingMapSearchBox
                    trackCompetitor={props.trackCompetitor}
                    competitors={props.competitors}
                />
                <TrackingMapBackButton/>
                <GeoJSON
                    key={(Object.entries(props.lineString).length===0)?1:indexOf(props.lineString.geometry.coordinates)}
                    data={props.lineString.geometry}
                    style={{color:"#f35744"}}/>
                <CheckpointsMarkers
                    checkpoints = {props.checkpoints}
                    numberOfCompetitors={props.competitors.length}
                    distanceName={props.distanceName}/>
                {(Object.entries(props.competitorTracker1).length ===0)?null:(
                <Marker position = {[props.competitorTracker1[0].currentLatitude, props.competitorTracker1[0].currentLongitude]}
                        icon = {getCompetitorMapIcon(30,props.competitorTracker1[0].number, props.competitorGender1,.8, 11)}>
                    <Popup>
                        <CompetitorTrackerPopup
                            stopCompetitorTracking={props.stopCompetitorTracking}
                            competitorTracker={props.competitorTracker1}
                            distanceTrack={props.distanceTrack}
                        />
                    </Popup>
                    <Tooltip>{props.competitorTracker1[0].fullName}</Tooltip>
                </Marker>)}
                {(Object.entries(props.competitorTracker2).length ===0)?null:(
                <Marker position = {[props.competitorTracker2[0].currentLatitude, props.competitorTracker2[0].currentLongitude]}
                        icon = {getCompetitorMapIcon(30,props.competitorTracker2[0].number, props.competitorGender2,.8,11)}>
                    <Popup>
                        <CompetitorTrackerPopup
                            stopCompetitorTracking={props.stopCompetitorTracking}
                            competitorTracker={props.competitorTracker2}
                            distanceTrack={props.distanceTrack}
                        />
                    </Popup>
                    <Tooltip>{props.competitorTracker2[0].fullName}</Tooltip>
                </Marker>)}
                {(Object.entries(props.competitorTracker3).length ===0)?null:(
                <Marker position = {[props.competitorTracker3[0].currentLatitude, props.competitorTracker3[0].currentLongitude]}
                        icon = {getCompetitorMapIcon(30,props.competitorTracker3[0].number, props.competitorGender3,.8,11)}>
                    <Popup>
                        <CompetitorTrackerPopup
                            stopCompetitorTracking={props.stopCompetitorTracking}
                            competitorTracker={props.competitorTracker3}
                            distanceTrack={props.distanceTrack}
                        />
                    </Popup>
                    <Tooltip>{props.competitorTracker3[0].number}</Tooltip>
                </Marker>)}
                {(Object.entries(props.competitorTracker4).length ===0)?null:(
                <Marker position = {[props.competitorTracker4[0].currentLatitude, props.competitorTracker4[0].currentLongitude]}
                        icon = {getCompetitorMapIcon(30,props.competitorTracker4[0].number, props.competitorGender4,.8,11)}>
                    <Popup>
                        <CompetitorTrackerPopup
                            stopCompetitorTracking={props.stopCompetitorTracking}
                            competitorTracker={props.competitorTracker4}
                            distanceTrack={props.distanceTrack}
                        />
                    </Popup>
                    <Tooltip>{props.competitorTracker4[0].number}</Tooltip>
                </Marker>)}

            </MapContainer>
        </div>
    )

}
export default TrackingMap;