import React from "react";
import './ElevationProfile.css';
import {
    Area,
    CartesianGrid,
    ComposedChart,
    Label,
    Line,
    LabelList, LineChart,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    Bar,
    XAxis,
    YAxis, BarChart
} from "recharts";



const ElevationProfileChart = (props) =>{

    const {checkpoints} = props;

    const data = checkpoints.map(checkpoint =>({elevation: checkpoint.checkpointElevation,
        name:checkpoint.checkpointName, accumulatedDistance: checkpoint.checkpointDistance}))
    console.log(data)

    return(
        <div className='elevation-profile-container' >
        <ResponsiveContainer>
            <ComposedChart
                width={1000}
                height={400}
                margin={{
                    top: 0, right: 20, left:0, bottom: 20,
                }}
            >
                <XAxis
                    dataKey="accumulatedDistance"
                    type="number"
                >
                    <Label value="Distance (mts) "
                           offset={0}
                           position="insideBottom"
                    />
                </XAxis>
                <YAxis type="number"
                       domain={['auto', 'auto']}
                       label={{angle: -90, position:'insideLeft', value:'Elevation (m)'}}
                />
                <CartesianGrid
                    strokeDasharray="3 3"
                    vertical={false}
                />
                <Bar
                    data = {data}
                    dataKey="elevation"
                    fill="#413ea0"
                    barSize={50}
                >
                    <LabelList dataKey="name"/>
                </Bar>
                <Line
                    dataKey="elevation"
                    stroke="#ca828a"
                    data = {data}
                >
                    <LabelList dataKey="name"/>

                </Line>
                <Area
                    dataKey="trackPointElevation"
                    type="monotone" stroke="green" fillOpacity={1}
                    fill="url(#colorPv)"
                    data={props.distanceTrack}
                />
                <defs>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#008058" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#008058" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <Scatter dataKey="currentAltitude" fill="#f35744" data={props.competitorTracker1} shape="wye">
                    <LabelList dataKey="number" position="top"/>
                </Scatter>
                <Scatter dataKey="currentAltitude" fill="#f35744" data={props.competitorTracker2} shape="circle">
                    <LabelList dataKey="number" position="top"/>
                </Scatter>
                <Scatter dataKey="currentAltitude" fill="#f35744" data={props.competitorTracker3} shape="cross">
                    <LabelList dataKey="number" position="top"/>
                </Scatter>
                <Scatter dataKey="currentAltitude" fill="#f35744" data={props.competitorTracker4} shape="star">
                    <LabelList dataKey="number" position="top"/>
                </Scatter>
            </ComposedChart>
        </ResponsiveContainer>

    </div>)
}
export default ElevationProfileChart;