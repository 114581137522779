import React, {useState, useEffect, useRef, useContext, useMemo} from 'react'
import './Races.css'
import { Link } from 'react-router-dom'
import FooterComponent from "../../layout/Footer";
import Navbar from "../../layout/Navbar";
import {ShowLinksContext} from "../../../context/ShowLinksContext";
import LoadingSpinner from "../../layout/LoadingSpinner";
import {RiTreasureMapLine} from 'react-icons/ri';
import {RiMapPinTimeLine} from 'react-icons/ri';
import {GoListOrdered} from 'react-icons/go';
import {BsPencilSquare} from 'react-icons/bs';
import {RacesContext} from "../../../context/RacesContextProvider";


const Races = () => {
    const [topMargin, setTopMargin] = useState([]);


    const wrapperRef = useRef(null);

    const {showLinks} = useContext(ShowLinksContext);

    const { races, loading, error} = useContext(RacesContext)

    // const {races, loading, error} = props.location.state;


            const { current } = wrapperRef;
            const getMarginTop = ()=>{

                let nodeStyle = window.getComputedStyle(current)
                let topMargin = nodeStyle.getPropertyValue('margin-top')
                setTopMargin(topMargin)

            }

    const renderCard = (race) => {

        return (

            <div className='race-card-container' key={race.id}>
                <div className='race-card-image' style={{backgroundImage:'url("'+race.picture+'")'}}/>
                <div className='race-card-body'>
                <h1 className='race-card-title'>{race.raceName}&nbsp;&nbsp;{Object.values(race.raceType.raceEnum)}</h1>
                <h3 className='race-card-subtitle'><b>Fecha de inicio:</b> {race.startDate}<br/><b>Fecha de Finilización:</b> {race.finishDate}<br/></h3>
                <h5>Distancias:</h5>
                {race.distances.map(distance =>
                <div key={distance.distanceId}>&nbsp;&nbsp;
                    <b>{distance.distanceName}</b>&nbsp;<br/>
                    <Link to={{
                        pathname: "/registrations",
                        state: {
                            race: race.raceName,
                            distanceObj: distance,
                            racePicture: race.picture,
                            raceIdDec: race.raceIdDec

                        }
                    }}>
                      <BsPencilSquare/><span>Inscripciones&nbsp;&nbsp;</span>
                    </Link>
                    <br/>
                    <Link to={{
                        pathname: "/results",
                        state: {
                            route: distance
                        }
                    }}>
                        <GoListOrdered/><span>Resultados</span>&nbsp;&nbsp;
                    </Link>
                    <br/>
                    <Link to={{
                        pathname: "/seeker",
                        state: {
                            raceName: race.raceName,
                            distanceName: distance.distanceName,
                            distanceObj: distance
                        }
                    }}>
                        <RiMapPinTimeLine/><span>Panel de la última ubicación registrada de los competidores</span>&nbsp;&nbsp;
                    </Link>
                    <br/>
                    <Link to={{
                        pathname: "/tracking",
                        state: {
                            race: race.raceName,
                            distanceName: distance.distanceName,
                            distanceObj: distance
                        }
                    }}>
                        <RiTreasureMapLine/><span>Ubicación predictiva de máximo 4 competidores con perfil de altura</span>
                    </Link>
                    <br/>
                    <Link to={{
                        pathname: "/tracking-all",
                        state: {
                            raceName: race.raceName,
                            distance: distance,

                        }
                    }}>
                        <RiTreasureMapLine/><span>Ubicación predictiva de todos los competidores</span>
                    </Link>
                </div>)}
                <br/>

                <p className='race-card-paragraph'>
                    Nuestro sistema de seguimiento predictivo, disponible en nuestra página web, se alimenta de una variedad de fuentes de datos recopiladas a lo largo de las competencias. Aunque nos esforzamos por asegurar la máxima precisión y fiabilidad de esta información, las variables dinámicas pueden generar algunas variaciones.
                    Por tanto, instamos a los usuarios a usar esta herramienta de manera consciente, considerando la posibilidad de ligeras discrepancias o imprecisiones en la información proporcionada. En caso de identificar alguna preocupación o dificultad, por favor, no dude en contactarnos inmediatamente. Su comprensión y colaboración son muy valiosas para nosotros mientras nos esforzamos por mejorar y enriquecer continuamente este sistema.<br/><br/>
                    {/*<a href="#">NEWS</a><br/>*/}
                </p>
                </div>
            </div>


        );
    }

    const toggleTopMargin = ()=> {
        let newMarginTop;
        let marginTopNumber = parseInt(topMargin[0])
        if (showLinks && marginTopNumber < 50) {
            newMarginTop = marginTopNumber + 175;
            console.log(newMarginTop)
            return {marginTop: newMarginTop}

        }
    }

    if (loading) return <LoadingSpinner/>
    if (error!== null)  return <p>{error}</p>

    return (
        <div>
            <Navbar/>
            <div className='race-cards-wrapper' ref={wrapperRef} style={toggleTopMargin()} >
                {races.map(renderCard)}
            </div>
            <FooterComponent/>
        </div>
    )
}
export default Races