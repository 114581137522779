import {Marker, Tooltip} from "react-leaflet";
import React, {useEffect, useState} from "react";
import L from "leaflet";
import {getCompetitorMapIcon} from "../../../commonFuntions";

const CompetitorTrackingAllMarker = (props) => {

    const {competitorsToShow} = props;

    return(
        competitorsToShow
            .filter(competitor => competitor.status !== "Retired")
            .map(competitor =>
                <Marker
                    key={competitor.number}
                    icon = {getCompetitorMapIcon(30,competitor.number, competitor.gender,.8,11)}
                    position = {[competitor.currentLatitude , competitor.currentLongitude]}
                >
                    <Tooltip>
                        <div>{competitor.number} {competitor.fullName}</div>
                        <div>{competitor.status === "Retired"?'Retirado':competitor.finishTime === null?'Tiempo para '+ competitor.nextCheckpoint:'Finisher'}</div>
                        <div>{competitor.status === "Retired"?'Retirado':competitor.finishTime === null?competitor.timeToNextCheckpoint: ' '}</div>
                    </Tooltip>
                </Marker>
            )
    )
}

export default CompetitorTrackingAllMarker