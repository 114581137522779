import React, {useState} from "react";
import './CheckoutConfirmModal.css'
import axios from 'axios';
import RegistrationVoucher from "./RegistrationVoucher";


const CheckoutConfirmModal = (props) =>{

    const {isOpenCardConfirmationModal, hideCardConfirmationModal, hideModalCardForm,
        paymentData, elements, stripe, formData, distanceName, raceIdDec, distanceStartDate} = props;

    console.log('RaceIdDec', raceIdDec);
    console.log(typeof raceIdDec);

    const [showRegistrationVoucher, setShowRegistrationVoucher] = useState(false);
    const [registerData, setRegisterData] = useState({});

    const toggleShowVoucher = () =>{
        setShowRegistrationVoucher(!showRegistrationVoucher);
    }

    const handleCancel = async (event)=>{
        event.preventDefault();
        if(!stripe || !elements){
            return;
        }

            try {
                const {data} = await axios.post(process.env.REACT_APP_BASE_URL + 'stripe/cancel/' + paymentData.id, {},);
                hideCardConfirmationModal();
                hideModalCardForm();
                console.log(data);

            } catch (error) {
                hideCardConfirmationModal();
                hideModalCardForm();
                console.log(error);
            }

    }

    const handleConfirm = async (event)=>{
        event.preventDefault();
        if(!stripe || !elements){
            return;
        }
        try {
            const {data} = await axios.post(process.env.REACT_APP_BASE_URL + 'stripe/confirm/' + paymentData.id, {
                // paymentMethodId: paymentMethodId
            });

            console.log('confirmation', data);

        } catch (error) {
            hideCardConfirmationModal();
            hideModalCardForm();
            console.log(error);
        }
        stripe.retrievePaymentIntent(paymentData.client_secret).then(function(response) {
            if (response.paymentIntent && response.paymentIntent.status === 'succeeded') {
                console.log('Payment confirmation data: ', response.paymentIntent)
                // Handle successful payment here call registration api and then open registrationVoucherModal
                axios.post(process.env.REACT_APP_BASE_URL + 'register', {
                    paymentId: paymentData.id, paymentMethodType: "card", firstName: formData.firstName,
                    lastName: formData.lastName, gender: formData.gender, email: formData.email,
                    nationality: formData.nationality, raceDistance: distanceName, dob: formData.birthday,
                    etd: distanceStartDate + 'T' + formData.departureTime, raceIdDec: raceIdDec
                })
                    .then(res=>{
                        setRegisterData(res.data);
                        console.log('Response data', res.data);
                        toggleShowVoucher();
                    })
                    .catch(err=>{
                        console.log(err);
                    });


                // setShowRegistrationVoucher(true)

            } else if (response.error){
                // Handle unsuccessful, processing, or canceled payments and API errors here
                console.log('Error: ', response.error)
                hideCardConfirmationModal();
                hideModalCardForm();

            }
        });
    }

    if(!isOpenCardConfirmationModal) return null;


    return  (
        <div className='checkout-modal-container'>
            <h5>ID:&nbsp;&nbsp;{paymentData.id}</h5>
            <div>Confirmar pago:</div>
            <button onClick={handleConfirm} >Confirmar</button>
            <button onClick={handleCancel}>Cancelar</button>
            <RegistrationVoucher
                showRegistrationVoucher={showRegistrationVoucher}
                toggleShowVoucher={toggleShowVoucher}
                hideModalCardForm={hideModalCardForm}
                registerData={registerData}
                distanceName={distanceName}
            />
        </div>
    )

}

export default CheckoutConfirmModal;