import React from "react";
import {useMap, useMapEvents} from "react-leaflet";
import L, {DomUtil} from "leaflet";
import './SeekerMapSearchBoxByParams.css'


const SeekerMapSearchBoxByParams = (props) => {

    const {competitors} = props;
    const map = useMap();

    let ulList,inputBox;

    useMapEvents({
        click() {
            if (inputBox.style.display==='inline-block') {
                inputBox.style.display = 'none'
                for (let i=0;i< ulList.childNodes.length; i++) {
                    ulList.childNodes[i].style.display = 'block'
                }
                ulList.style.display = 'none'
            }
        }
    });

    L.Control.MapSearchBox = L.Control.extend({

        onAdd: (map) => {

            const toggleUl = () =>{
                if(ulList.style.display === 'none'){
                    ulList.style.display = 'block';
                    ulList.style.cursor = 'pointer'
                }else {
                    ulList.style.display = 'none'
                    ulList.style.cursor = 'none'
                }
            }

            let competitorsLi;
            const filterCompetitors = ()=>{

                let value = inputBox.value;
                competitorsLi = ulList.childNodes;

                for (let i=0;i< competitorsLi.length; i++){
                     let name = competitorsLi[i]
                    if (name.innerText.toLowerCase().toString().indexOf(value.toString().toLowerCase())> -1){
                        competitorsLi[i].style.display = 'block';
                    }else {
                        competitorsLi[i].style.display = 'none'
                    }
                }
            }

            const searchDiv = DomUtil.create("div", "map-search-box-div");
            L.DomEvent.disableClickPropagation(searchDiv)

            const searchButton = document.createElement('button')
            searchButton.classList.add('map-search-box-button')

            searchButton.onclick = ()=>{
                if(inputBox.style.display === 'none'){
                    inputBox.style.display = 'inline-block'
                    inputBox.value = ""
                }else {
                    inputBox.style.display = 'none'
                    for (let i=0;i< ulList.childNodes.length; i++){
                        ulList.childNodes[i].style.display = 'block'
                    }
                    ulList.style.display = 'none'
                }
            }

            const searchIcon = document.createElement('i')
            searchIcon.classList.add('fa','fa-search', 'search-icon')
            searchIcon.setAttribute('aria-hidden', 'true')

            const inputBoxContainer = document.createElement('div')
            inputBoxContainer.classList.add('input-box-container')

             inputBox = document.createElement('input');
            inputBox.classList.add('map-search-box-input')
            inputBox.setAttribute('type', 'text');
            inputBox.setAttribute('placeholder','Buscar competidor ....')
            inputBox.addEventListener('click', toggleUl)
            inputBox.addEventListener('keyup', filterCompetitors)

            ulList = document.createElement('ul')
            ulList.classList.add('map-search-ul')

            competitors.forEach((competitor)=>{
                let liList = document.createElement('li');

                liList.innerText = competitor.firstName + " " + competitor.lastName + " " + competitor.competitorNumber;
                ulList.appendChild(liList);
            });

            searchButton.appendChild(searchIcon)
            searchDiv.appendChild(searchButton)
            searchDiv.appendChild(inputBoxContainer)
            inputBoxContainer.appendChild(inputBox)
            inputBoxContainer.appendChild(ulList)

            const onSelectionLi = (i) =>{

                inputBox.value = ulList.children[i].innerText
                flyToMarker(inputBox.value.split(" ").pop());
                ulList.style.display = 'none'
                inputBox.style.display = 'none'
            }

            const flyToMarker = (competitorNumber) =>{

                let marker;
                let markerNumber;

                map.eachLayer((layer) =>  {
                    if(layer instanceof L.Marker) {
                        marker = layer
                        if(marker.options.icon.options.html) {
                            markerNumber = marker.options.icon.options.html.attributes[1].nodeValue
                                .slice(-4).match(/\d/g).join("");
                            if(markerNumber === competitorNumber){
                                map.flyTo(marker.getLatLng(), 18)
                                marker.openPopup()
                            }
                        }
                    }
                });
            }

            for (let i = 0; i< ulList.children.length; i++)
            {
                ulList.children[i].onclick = () =>{
                     return onSelectionLi(i);
                }
            }

            return searchDiv;
        },
        onRemove: (map) => {},
    });

    L.control.MapSearchBox = (opts) =>{
        return new L.Control.MapSearchBox(opts)
    }

    L.control.MapSearchBox({ position: 'topleft'}).addTo(map);

    return null;
}

export default SeekerMapSearchBoxByParams;