import React, {useState} from "react";
import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import axios from 'axios';
import './CardCheckoutForm.css'
import CheckoutConfirmModal from "../../modals/CheckoutConfirmModal";
import ReactDom from "react-dom";
import stripeIcon from '../../../images/stripe-icon-dark.svg'

const {REACT_APP_BASE_URL} = process.env;

const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "#fff",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
                color: "#fce883"
            },
            "::placeholder": {
                color: "#87bbfd"
            }
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee"
        }
    }
};


const CardCheckoutForm = (props) => {

    const [isOpenCardConfirmationModal, setIsOpenCardConfirmationModal] = useState(false);
    const [paymentData, setPaymentData] = useState({});
    const [paymentMethodId, setPaymentMethodId] = useState("");

    const {raceIdDec,formData,distanceName, distanceStartDate, registerAmount, isOpenCardForm, hideModalCardForm} = props;

    const stripe = useStripe();
    const elements = useElements();

    const showCardConfirmationModal = () => {
        setIsOpenCardConfirmationModal(true);
    };
    const hideCardConfirmationModal = () => {
        setIsOpenCardConfirmationModal(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)
        });

        if(!error){
            // const id = paymentMethod;
            setPaymentMethodId(paymentMethod.id);
            try {
                const {data} = await axios.post(REACT_APP_BASE_URL + 'stripe/create-payment-intent',
                    {amount:registerAmount, description:distanceName, currency: "mxn", paymentMethodType:"card"});
                console.log(data);
                setPaymentData(data);
                showCardConfirmationModal();
                // console.log(paymentMethod);
                // console.log(paymentMethodId)
                
            }catch (error) {
                console.log(error)
                
            }
        }
    };

    if(!isOpenCardForm) return null;

    return  ReactDom.createPortal(

        <div className='card-checkout-form-overlay'>
        <div className='card-checkout-form-container'>
            <button className='card-payment-form-close-button' onClick={hideModalCardForm}>X</button>
            <form onSubmit={handleSubmit}  >
                <img
                    src="https://cdn.pixabay.com/photo/2020/01/11/13/22/woman-4757533_960_720.jpg"
                    alt='race picture' />
                    <h5>Inscripción</h5>
                <h4>{distanceName}</h4>
                <h5>Costo {(registerAmount/100).toFixed(2)} MNX</h5>
                <CardElement   options={{
                    style: {
                        base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                                color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    },
                }} />
                <button type="submit" disabled={!stripe || isOpenCardConfirmationModal}>
                    Pagar con tarjeta
                </button>
                <img src={stripeIcon} alt='stripe icon' />
            </form>

            <CheckoutConfirmModal isOpenCardConfirmationModal={isOpenCardConfirmationModal}
                                  hideCardConfirmationModal={hideCardConfirmationModal}
                                  paymentData={(!paymentData)?null:paymentData}
                                  stripe={stripe}
                                  elements={elements}
                                  paymentMethodId={paymentMethodId}
                                  hideModalCardForm={hideModalCardForm}
                                  formData={formData}
                                  distanceName={distanceName}
                                  raceIdDec={raceIdDec}
                                  distanceStartDate={distanceStartDate}
            />
        </div>
        </div>,
        document.getElementById('checkout-card-portal')
    );
};
export default CardCheckoutForm;