// import React, { createContext, useState, useEffect } from 'react';
// import axios from 'axios';
//
// const { REACT_APP_BASE_URL } = process.env;
//
// export const RacesContext = createContext();
//
// export const RacesContextProvider = (props) => {
//     const [races, setRaces] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//
//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const res = await axios.get(REACT_APP_BASE_URL + 'races')
//                 setRaces(res.data.slice().reverse())
//                 setLoading(false)
//             } catch (error) {
//                 setError(error)
//                 setLoading(false)
//             }
//         }
//
//     }, [])
//
//     return (
//         <RacesContext.Provider value={{ races, loading, error }}>
//             {props.children}
//         </RacesContext.Provider>
//     );
// }
//
// import React, { useReducer, useEffect } from 'react';
// import axios from 'axios';
//
// const { REACT_APP_BASE_URL } = process.env;
//
// const racesReducer = (state, action) => {
//     switch (action.type) {
//         case 'FETCH_START':
//             return { ...state, loading: true, error: null };
//         case 'FETCH_SUCCESS':
//             return {
//                 ...state,
//                 races: action.payload.slice().reverse(),
//                 loading: false,
//                 error: null,
//             };
//         case 'FETCH_ERROR':
//             return { ...state, loading: false, error: action.payload };
//         default:
//             throw new Error(`Unhandled action type: ${action.type}`);
//     }
// };
//
// export const RacesContext = React.createContext();
//
// export const RacesContextProvider = (props) => {
//     const [state, dispatch] = useReducer(racesReducer, {
//         races: [],
//         loading: false,
//         error: null,
//     });
//
//     useEffect(() => {
//         const fetchData = async () => {
//             dispatch({ type: 'FETCH_START' });
//             try {
//                 const res = await axios.get(REACT_APP_BASE_URL + 'races');
//                 dispatch({ type: 'FETCH_SUCCESS', payload: res.data });
//             } catch (error) {
//                 dispatch({ type: 'FETCH_ERROR', payload: error });
//             }
//         };
//         fetchData();
//     }, []);
//
//     return (
//         <RacesContext.Provider value={state}>
//             {props.children}
//         </RacesContext.Provider>
//     );
// };
// import React, { createContext, useState, useEffect, useMemo } from 'react';
// import axios from 'axios';
//
// const { REACT_APP_BASE_URL } = process.env;
//
// export const RacesContext = createContext();
//
// export const RacesContextProvider = (props) => {
//     const [races, setRaces] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//
//     const value = useMemo(() => {
//         const fetchData = async () => {
//             try {
//                 const res = await axios.get(REACT_APP_BASE_URL + 'races');
//                 setRaces(res.data.slice().reverse());
//                 setLoading(false);
//             } catch (error) {
//                 setError(error);
//                 setLoading(false);
//             }
//         };
//
//         if (loading) {
//             fetchData();
//         }
//
//         return { races, loading, error };
//     }, [loading]);
//
//     return (
//         <RacesContext.Provider value={value}>
//             {props.children}
//         </RacesContext.Provider>
//     );
// };

// import React, {createContext, useEffect, useReducer} from 'react';
// import axios from 'axios';
//
// const { REACT_APP_BASE_URL } = process.env;
//
// const initialState = {
//     races: [],
//     loading: true,
//     error: null,
// };
//
// const racesReducer = (state, action) => {
//     switch (action.type) {
//         case 'FETCH_RACES_SUCCESS':
//             return {
//                 ...state,
//                 races: action.payload,
//                 loading: false,
//                 error: null,
//             };
//         case 'FETCH_RACES_ERROR':
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload,
//             };
//         default:
//             return state;
//     }
// };
//
// export const RacesContext = createContext();
//
// export const RacesContextProvider = (props) => {
//     const [state, dispatch] = useReducer(racesReducer, initialState);
//
//     useEffect(() => {
//         if (!state.races.length) {
//             const fetchData = async () => {
//                 try {
//                     const res = await axios.get(REACT_APP_BASE_URL + 'races');
//                     dispatch({ type: 'FETCH_RACES_SUCCESS', payload: res.data.slice().reverse() });
//                 } catch (error) {
//                     dispatch({ type: 'FETCH_RACES_ERROR', payload: error });
//                 }
//             };
//             fetchData();
//         }
//     }, [state.races]);
//
//     return (
//         <RacesContext.Provider value={{ ...state }}>
//             {props.children}
//         </RacesContext.Provider>
//     );
// };
// import React, { createContext, useState, useEffect, useMemo } from 'react';
// import axios from 'axios';
//
// const { REACT_APP_BASE_URL } = process.env;
//
// export const RacesContext = createContext();
//
// export const RacesContextProvider = React.memo((props) => {
//     const [races, setRaces] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//
//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const res = await axios.get(REACT_APP_BASE_URL + 'races');
//                 setRaces(res.data.slice().reverse());
//                 setLoading(false);
//             } catch (error) {
//                 setError(error);
//                 setLoading(false);
//             }
//         }
//         fetchData();
//     }, []);
//
//     const value = useMemo(() => ({
//         races,
//         loading,
//         error
//     }), [races, loading, error]);
//
//     return (
//         <RacesContext.Provider value={value}>
//             {props.children}
//         </RacesContext.Provider>
//     );
// }, (prevProps, nextProps) => {
//     return prevProps.races === nextProps.races;
// });
// import React, {createContext, useReducer, useMemo, useEffect} from 'react';
// import axios from 'axios';
//
// const { REACT_APP_BASE_URL } = process.env;
//
// const initialState = {
//     races: [],
//     loading: true,
//     error: null,
// };
//
// const reducer = (state, action) => {
//     switch (action.type) {
//         case 'FETCH_RACES_START':
//             return {
//                 ...state,
//                 loading: true,
//                 error: null,
//             };
//         case 'FETCH_RACES_SUCCESS':
//             return {
//                 ...state,
//                 races: action.payload.races,
//                 loading: false,
//             };
//         case 'FETCH_RACES_ERROR':
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload.error,
//             };
//         default:
//             return state;
//     }
// };
//
// export const RacesContext = createContext();
//
// export const RacesContextProvider = React.memo((props) => {
//     const [state, dispatch] = useReducer(reducer, initialState);
//
//     useEffect(() => {
//         dispatch({ type: 'FETCH_RACES_START' });
//
//         const fetchData = async () => {
//             try {
//                 const res = await axios.get(REACT_APP_BASE_URL + 'races');
//                 dispatch({ type: 'FETCH_RACES_SUCCESS', payload: { races: res.data.slice().reverse() } });
//             } catch (error) {
//                 dispatch({ type: 'FETCH_RACES_ERROR', payload: { error } });
//             }
//         }
//         fetchData();
//     }, []);
//
//     const value = useMemo(() => ({
//         races: state.races,
//         loading: state.loading,
//         error: state.error,
//     }), [state]);
//
//     return (
//         <RacesContext.Provider value={value}>
//             {props.children}
//         </RacesContext.Provider>
//     );
// }, (prevProps, nextProps) => {
//     return prevProps.races === nextProps.races;
// });

// import React, { createContext, useState, useEffect, useMemo } from 'react';
// import axios from 'axios';
//
// const { REACT_APP_BASE_URL } = process.env;
//
// export const RacesContext = createContext();
//
// export const RacesContextProvider = React.memo((props) => {
//     const [races, setRaces] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [isFetched, setIsFetched] = useState(false);
//
//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const res = await axios.get(REACT_APP_BASE_URL + 'races');
//                 setRaces(res.data.slice().reverse());
//                 setLoading(false);
//                 setIsFetched(true);
//             } catch (error) {
//                 setError(error);
//                 setLoading(false);
//                 setIsFetched(true);
//             }
//         };
//         if (!isFetched) {
//             fetchData();
//         }
//     }, [isFetched]);
//
//     const value = useMemo(() => ({
//         races,
//         loading,
//         error
//     }), [races, loading, error]);
//
//     return (
//         <RacesContext.Provider value={value}>
//             {props.children}
//         </RacesContext.Provider>
//     );
// }, (prevProps, nextProps) => {
//     return prevProps.races === nextProps.races;
// });

import React, {useState, useEffect, createContext} from 'react';
import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env;
export const RacesContext = createContext();

const RacesContextProvider = (props) => {
    const [races, setRaces] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(REACT_APP_BASE_URL + 'races');
                setRaces(res.data.slice().reverse());
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    return (
        <RacesContext.Provider value={{ races, loading, error }}>
            {props.children}
        </RacesContext.Provider>
    );
};

export default RacesContextProvider;








