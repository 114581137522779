import React, {useState} from "react";
import Table from "react-bootstrap/Table";
import './TrackingAllTable.css'


const TrackingAllTable = (props) => {

    const {competitorsToShow, getInRaceTime, getTimeInterval, search,  selectedGender, handleGenderSelection, handleRowClick} = props;
    const [searchQuery,setSearchQuery] = useState("");


    const getCompetitorStatus = (status) => {
        if (status === "Finisher") {
            return "Finisher"
        }else if (status === "Competing") {
            return "Compitiendo"
        }else if (status === "Retired") {
            return "Retirado"
        }else if (status === "No CHK") {
            return "No CHK"
        }
    }

    function compareCompetitors(a, b) {
        if (a.status === "Retired" && b.status !== "Retired") {
            return 1;
        } else if (b.status === "Retired" && a.status !== "Retired") {
            return -1;
        } else if (a.status === "No CHK") {
            return 1;
        } else if (b.status === "No CHK") {
            return -1;
        } else if (a.finishTime !== null && b.finishTime !== null){
            let finishTimeA = getTimeInterval(a.finishTime, a.startTime);
            let finishTimeB = getTimeInterval(b.finishTime, b.startTime);
            return finishTimeA.localeCompare(finishTimeB)
        }else {
            const numCheckpointsA = a.checkedCheckpoints.length;
            const numCheckpointsB = b.checkedCheckpoints.length;

            if (numCheckpointsA > numCheckpointsB) {
                return -1;
            } else if (numCheckpointsA < numCheckpointsB) {
                return 1;
            } else {

                const sortedCheckpointsA = a.checkedCheckpoints.slice().sort((c1, c2) => c1.competitorTimestamp - c2.competitorTimestamp);
                const sortedCheckpointsB = b.checkedCheckpoints.slice().sort((c1, c2) => c1.competitorTimestamp - c2.competitorTimestamp);

                const lastCheckpointA = sortedCheckpointsA[sortedCheckpointsA.length - 1];
                const lastCheckpointB = sortedCheckpointsB[sortedCheckpointsB.length - 1];

                const lastCheckpointTimeA = getTimeInterval(lastCheckpointA.competitorTimestamp, a.startTime);
                const lastCheckpointTimeB = getTimeInterval(lastCheckpointB.competitorTimestamp , b.startTime);
                return lastCheckpointTimeA.localeCompare(lastCheckpointTimeB)


            }
        }
    }

    return(
        <>
            <div style={{padding: '1rem'}}>
                <input className="input-group mb-3" type='text' placeholder='Buscar competidor...' value={searchQuery} onChange={e => setSearchQuery(e.target.value)}/>
                <select className="input-group mb-3" value={selectedGender} onChange={e => handleGenderSelection(e.target.value)}>
                    <option value="">General</option>
                    <option value="FEMALE">Femenino</option>
                    <option value="MALE">Masculino</option>
                </select>
            </div>
            <div className='container' >
                <Table striped hover responsive className='table-fixed'>
                <tbody>
                {
                    // search(filterByGender(competitorsTracking, selectedGender), searchQuery)
                    search(competitorsToShow, searchQuery)
                        .sort(compareCompetitors)
                        .map(competitor =>
                            <tr key={competitor.number} onClick={() => handleRowClick(competitor)} className="text-sm font-weight-bold" style={{cursor: 'pointer'}}>
                                <td style={{width: '3%', fontSize: '14px'}}> {competitor.number}</td>
                                <td style={{width: '51%', fontSize: '14px'}}>{competitor.fullName}</td>
                                <td style={{width: '6%', fontSize: '14px'}}>
                                    {competitor.status === "Retired" ? "-" : competitor.finishTime === null ? getInRaceTime(competitor.startTime) : getTimeInterval(competitor.finishTime, competitor.startTime)}
                                </td>
                                <td style={{width: '40%', fontSize: '14px'}}>{getCompetitorStatus(competitor.status)}</td>
                            </tr>
                        )
                }
                </tbody>
            </Table>
            </div>
        </>
    )

}

export default TrackingAllTable