import React, {useEffect, useState} from "react";
import axios from "axios";
import {GeoJSON, LayersControl, MapContainer, Pane, TileLayer, ZoomControl} from "react-leaflet";
import "leaflet/dist/leaflet.css"
import './CompetitorSeekerDashboardByParams.css'
import {indexOf} from "leaflet/src/core/Util";
import CheckpointsMarkers from "../tracking/CheckpointsMarkers";
import CompetitorsLocationMarkersByParams from "./CompetitorsLocationMarkersByParams";
import SeekerMapTitleByParams from "./SeekerMapTitleByParams";
import SeekerMapSearchBoxByParams from "./SeekerMapSearchBoxByParams";
import LoadingSpinner from "../../layout/LoadingSpinner";
import {useParams} from "react-router-dom";


const CompetitorSeekerDashboardByParams = () =>{

    const {REACT_APP_BASE_URL} = process.env;

    const {distanceParam} = useParams();

    const [competitors, setCompetitors] = useState([]);
    const [lineString,setLineString] = useState({});
    const [distanceObj, setDistanceObj] = useState(null)

    useEffect(() =>{

        axios.get(REACT_APP_BASE_URL + distanceParam)
            .then(res=> {
                setDistanceObj(res.data);
            })
            .catch(err=>{
                console.log(err);
            });
    },[]);

    useEffect(()=>{
        if(distanceObj != null){
            setLineString(getLineString(distanceObj.distanceTrack));
        }
    }, [distanceObj]);

    useEffect(()=>{
        if(competitors.length === 0 ) {
            getCompetitors();
        }
    }, []);

    const getCompetitors = () =>{
        axios.get(REACT_APP_BASE_URL + "competitors/" + distanceParam)
            .then(res=>{
                setCompetitors(res.data);

                console.log(competitors);

            })
            .catch(err=>{
                console.log(err);
            });
    }

    const getLineString = (trackPath) =>{

            let coordinates = trackPath.map((tp) => [
                tp.trackPointLongitude,
                tp.trackPointLatitude,
            ]);
            return {
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: coordinates
                },
                properties: {}
            };
        }


    if (distanceObj == null || competitors.length ===0) return <LoadingSpinner/>

    return(

        <div className='seeker-dashboard-container'>
            <MapContainer
                center={[distanceObj.distanceTrack[0].trackPointLatitude, distanceObj.distanceTrack[0].trackPointLongitude]}
                zoom={14}
                scrollWheelZoom={true}
                maxZoom={20}
                doubleClickZoom={false}
                zoomControl={false}>
                <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Esri.WorldImagery">
                        <TileLayer
                            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        />
                    </LayersControl.BaseLayer>
                </LayersControl>
                <GeoJSON
                    key={(Object.entries(lineString).length===0)?1:indexOf(lineString.geometry.coordinates)}
                    data={lineString.geometry}
                    style={{color:"#f35744"}}
                />
                <ZoomControl position='bottomright'/>
                <CheckpointsMarkers
                    checkpoints = {distanceObj.checkpoints}
                    numberOfCompetitors={competitors.length}
                    distanceName={distanceParam}
                />
                <CompetitorsLocationMarkersByParams
                    distanceName={distanceParam}
                />
                <SeekerMapTitleByParams
                    distanceName={distanceParam}/>
                <SeekerMapSearchBoxByParams
                    competitors={competitors}
                />
                {/*<SeekerMapBackButtonByParams/>*/}
            </MapContainer>
        </div>
    )
}

export default CompetitorSeekerDashboardByParams;