import React, {useEffect, useState} from "react";
import L from "leaflet";
import {Marker, Popup, useMap} from "react-leaflet";
import CompetitorsLocationMarkersPopup from "./CompetitorsLocationMarkersPopup";
import LoadingSpinner from "../../layout/LoadingSpinner";
import {getCompetitorMapIcon} from "../../../commonFuntions";

const CompetitorsLocationMarkers = (props) =>{

    const {REACT_APP_BASE_URL} = process.env;

    const {distanceName} = props;

    const [competitorsLocation, setCompetitorsLocation] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(()=>{
        let competitorsLocationEvent = null;
        let eventInterval = null;

        const url = REACT_APP_BASE_URL + 'last-location-flux/' + distanceName;

        competitorsLocationEvent =  new EventSource(url);
        competitorsLocationEvent.onmessage =  e => {
            setCompetitorsLocation(JSON.parse(e.data));
            setLoading(false);
            console.log(competitorsLocation)
        }

        eventInterval = setInterval(()=>{

            competitorsLocationEvent.close();
            competitorsLocationEvent =  new EventSource(url);
            competitorsLocationEvent.onmessage =  e => setCompetitorsLocation(JSON.parse(e.data));


            competitorsLocationEvent.onerror = () =>{
                console.log("Ey there is an error");
                alert("There is not data for at the moment");
                setLoading(true)

                competitorsLocationEvent.close();
                setCompetitorsLocation([]);
                clearInterval(eventInterval);
            }
        },840000);

        competitorsLocationEvent.onerror = () =>{
            console.log("Ey there is an error");
            alert("There is not data for at the moment")

            competitorsLocationEvent.close();
            setCompetitorsLocation([]);
            clearInterval(eventInterval);
        }


        return ()=>{
            clearInterval(eventInterval);
            if(!!competitorsLocationEvent){
                competitorsLocationEvent.close();
            }
        }
    },[]);

    const adjustCoordinate = ( min, max, seed,coordinate)=> {
        min = min || 0;
        max = max || 1;
        let rand;
        if ( typeof seed === "number" ) {
            seed = ( seed * 9301 + 49297 ) % 233280;
            let rnd = seed / 233280;
            let disp = Math.abs( Math.sin( seed ) );
            rnd = ( rnd + disp ) - Math.floor( ( rnd + disp ) );
            rand = Math.floor( min + rnd * ( max - min + 1 ) );
        } else {
            rand  = Math.floor( Math.random() * ( max - min + 1 ) ) + min;
        }
        return coordinate + rand/100000;
    }

    if (loading) return <LoadingSpinner/>

    return(
        competitorsLocation.map(competitor=>
            <Marker
                key={competitor.number}
                icon={getCompetitorMapIcon(30,competitor.number, competitor.gender,.8,11)}
                position={[adjustCoordinate(-10,10,competitor.number,competitor.lastReaderData.latitude), adjustCoordinate(-10,10,-competitor.number,competitor.lastReaderData.longitude)]}>
                <Popup>
                    <CompetitorsLocationMarkersPopup
                        competitorFullName={competitor.fullName}
                        competitorNumber={competitor.number}
                        scanTimestamp={competitor.lastReaderData.timestamp}
                        startTime={competitor.startTime}
                        finishTime={competitor.finishTime}
                        distanceOnTrack={competitor.distanceOnTrack}
                        elevationGainOnTrack={competitor.elevationGainOnTrack}
                        checkedCheckpoints={competitor.checkedCheckpoints}
                        latitude={adjustCoordinate(-10,10,competitor.number,competitor.lastReaderData.latitude)}
                        longitude={adjustCoordinate(-10,10,-competitor.number,competitor.lastReaderData.longitude)}
                    />
                </Popup>
            </Marker>
        )

    )

}

export default CompetitorsLocationMarkers;