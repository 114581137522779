import {useHistory} from "react-router";
import React, {useEffect, useState} from "react";
import TrackingMap from './TrackingMap';
import ElevationProfileChart from "./ElevationProfileChart";
import Axios from "axios";
import LoadingSpinner from "../../layout/LoadingSpinner";

const CompetitorTrackerDashboard = (props) =>{

    const {REACT_APP_BASE_URL} = process.env

    const history = useHistory();
    const [competitors, setCompetitors] = useState([]);
    const [loading, setLoading] = useState(true)
    const [checkpoints,setCheckpoints] = useState([]);
    const [distanceTrack, setDistanceTrack] = useState([]);
    const [lineString,setLineString] = useState({})
    const [competitorGender, setCompetitorGender] = useState("")
    const [competitorTracker1,setCompetitorTracker1] = useState([]);
    const [competitorNumber1, setCompetitorNumber1]=useState(null);
    const [competitorGender1, setCompetitorGender1] = useState("")
    const [competitorTracker2,setCompetitorTracker2] = useState([]);
    const [competitorNumber2, setCompetitorNumber2]=useState(null);
    const [competitorGender2, setCompetitorGender2] = useState("")
    const [competitorTracker3,setCompetitorTracker3] = useState([]);
    const [competitorNumber3, setCompetitorNumber3]=useState(null);
    const [competitorGender3, setCompetitorGender3] = useState("")
    const [competitorTracker4,setCompetitorTracker4] = useState([]);
    const [competitorNumber4, setCompetitorNumber4]=useState(null);
    const [competitorGender4, setCompetitorGender4] = useState("");


    useEffect( () =>{

        Axios.get(REACT_APP_BASE_URL + "competitors/" + props.location.state.distanceName)
            .then(res=>{
                setCompetitors(res.data);
                setLoading(false)
                console.log("Competitors " + competitors);


            })
            .catch(err=>{
                console.log(err);
            });

        setDistanceTrack(props.location.state.distanceObj.distanceTrack);
        setLineString(getLineString(props.location.state.distanceObj.distanceTrack));
        setCheckpoints(props.location.state.distanceObj.checkpoints.filter(checkpoint => checkpoint.checkpointId !== 8))

    },[]);


    const getLineString = (trackPath) =>{

        let coordinates = trackPath.map((tp) => [
            tp.trackPointLongitude,
            tp.trackPointLatitude,
        ]);
        return {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: coordinates
            },
            properties: {}
        };
    }

    const  trackCompetitor =  (competitorNumber,gender)=>{
        if(!(competitorNumber ===competitorNumber1 || competitorNumber ===competitorNumber2  ||
            competitorNumber ===competitorNumber3 || competitorNumber ===competitorNumber4)) {
            if (competitorNumber1 === null) {
                setCompetitorNumber1(competitorNumber);
                setCompetitorGender1(gender);
            } else if (competitorNumber2 === null) {
                setCompetitorNumber2(competitorNumber)
                setCompetitorGender2(gender);
            } else if (competitorNumber3 === null) {
                setCompetitorNumber3(competitorNumber)
                setCompetitorGender3(gender);
            } else if (competitorNumber4 === null) {
                setCompetitorNumber4(competitorNumber)
                setCompetitorGender4(gender);
            } else {
                alert("Exceeded the maximum number of competitors")
            }
        }
    }

    const stopCompetitorTracking = (competitorNumber)=>{
        if(competitorNumber1===competitorNumber){
            setCompetitorNumber1(null);
            setCompetitorTracker1([])

            console.log('CompetitorTracking ' + competitorNumber + ' is stop')
        }else if(competitorNumber2 ===competitorNumber){
            setCompetitorNumber2(null)
            setCompetitorTracker2([])
        }else if(competitorNumber3 ===competitorNumber){
            setCompetitorNumber3(null)
            setCompetitorTracker3([])
        }else if (competitorNumber4===competitorNumber){
            setCompetitorNumber4(null)
            setCompetitorTracker4([])
        }

    }


    useEffect(()=>{
        let competitorTrackerEvent1 = null;
        let eventInterval1 = null;

        if(!!competitorNumber1){
            const url = REACT_APP_BASE_URL + "competitor_tracker/"
                +props.location.state.distanceName +"/" + competitorNumber1
            competitorTrackerEvent1 =  new EventSource( url);
            competitorTrackerEvent1.onmessage =  e => updateCompetitorTracker1(JSON.parse(e.data));

                eventInterval1 = setInterval(()=>{
                competitorTrackerEvent1.close();
                competitorTrackerEvent1 =  new EventSource(url);
                competitorTrackerEvent1.onmessage =  e => updateCompetitorTracker1(JSON.parse(e.data));
                console.log(`Open eventSourceInterval`);
                competitorTrackerEvent1.onerror = () =>{
                    console.log("Ey there is an error");
                    alert("No tracking data for  competitor " + competitorNumber1  +" at the moment")

                    competitorTrackerEvent1.close();
                    setCompetitorTracker1([]);
                    setCompetitorNumber1(null);
                    clearInterval(eventInterval1);
                }
            },840000);

            competitorTrackerEvent1.onerror = () =>{
                console.log("Ey there is an error");
                alert("No tracking data for  competitor " + competitorNumber1  +" at the moment")

                competitorTrackerEvent1.close();
                setCompetitorTracker1([]);
                setCompetitorNumber1(null);
                clearInterval(eventInterval1);
            }
        }

        return ()=>{
            clearInterval(eventInterval1);
            if(!!competitorTrackerEvent1){
                competitorTrackerEvent1.close();
            }
        }
    },[competitorNumber1]);

    useEffect(()=>{
        let competitorTrackerEvent2 = null;
        let eventInterval2 = null;

        if(!!competitorNumber2){
            const url = REACT_APP_BASE_URL + "competitor_tracker/"
                +props.location.state.distanceName +"/" + competitorNumber2
            competitorTrackerEvent2 =  new EventSource(url);
            competitorTrackerEvent2.onmessage =  e => updateCompetitorTracker2(JSON.parse(e.data));

            eventInterval2 = setInterval(()=>{
                competitorTrackerEvent2.close();
                competitorTrackerEvent2 =  new EventSource(url);
                competitorTrackerEvent2.onmessage =  e => updateCompetitorTracker2(JSON.parse(e.data));
                console.log(`Open eventSourceInterval`);
                competitorTrackerEvent2.onerror = () =>{
                    console.log("Ey there is an error");
                    alert("No tracking data for  competitor " + competitorNumber2  +" at the moment")

                    competitorTrackerEvent2.close();
                    setCompetitorTracker2([]);
                    setCompetitorNumber2(null);
                    clearInterval(eventInterval2);
                }
            },840000);

            competitorTrackerEvent2.onerror = () =>{
                console.log("Ey there is an error");
                alert("No tracking data for  competitor " + competitorNumber2  +" at the moment")

                competitorTrackerEvent2.close();
                setCompetitorTracker2([]);
                setCompetitorNumber2(null);
                clearInterval(eventInterval2);
            }
        }
        return ()=>{
            clearInterval(eventInterval2);
            if(!!competitorTrackerEvent2){
                competitorTrackerEvent2.close();

            }
        }
    },[competitorNumber2]);

    useEffect(()=>{
        let competitorTrackerEvent3 = null;
        let eventInterval3 = null;

        if(!!competitorNumber3){
            const url = REACT_APP_BASE_URL + "competitor_tracker/"
                +props.location.state.distanceName +"/" + competitorNumber3
            competitorTrackerEvent3 =  new EventSource(url);
            competitorTrackerEvent3.onmessage =  e => updateCompetitorTracker3(JSON.parse(e.data));

            eventInterval3 = setInterval(()=>{
                competitorTrackerEvent3.close();
                competitorTrackerEvent3 =  new EventSource(url);
                competitorTrackerEvent3.onmessage =  e => updateCompetitorTracker3(JSON.parse(e.data));
                console.log(`Open eventSourceInterval`);
                competitorTrackerEvent3.onerror = () =>{
                    console.log("Ey there is an error");
                    alert("No tracking data for  competitor " + competitorNumber3  +" at the moment")

                    competitorTrackerEvent3.close();
                    setCompetitorTracker3([]);
                    setCompetitorNumber3(null);
                    clearInterval(eventInterval3);
                }
            },840000);

            competitorTrackerEvent3.onerror = () =>{
                console.log("Ey there is an error");
                alert("No tracking data for  competitor " + competitorNumber3  +" at the moment")

                competitorTrackerEvent3.close();
                setCompetitorTracker3([]);
                setCompetitorNumber3(null);
                clearInterval(eventInterval3);
            }
        }

        return ()=>{
            clearInterval(eventInterval3);
            if(!!competitorTrackerEvent3){
                competitorTrackerEvent3.close();
            }
        }
    },[competitorNumber3]);

    useEffect(()=>{
        let competitorTrackerEvent4 = null;
        let eventInterval4 = null;

        if(!!competitorNumber4){
            const url = REACT_APP_BASE_URL + "competitor_tracker/"
                +props.location.state.distanceName +"/" + competitorNumber4
            competitorTrackerEvent4 =  new EventSource(url);
            competitorTrackerEvent4.onmessage =  e => updateCompetitorTracker4(JSON.parse(e.data));

            eventInterval4 = setInterval(()=>{
                competitorTrackerEvent4.close();
                competitorTrackerEvent4 =  new EventSource(url);
                competitorTrackerEvent4.onmessage =  e => updateCompetitorTracker4(JSON.parse(e.data));
                console.log(`Open eventSourceInterval`);
                competitorTrackerEvent4.onerror = () =>{
                    console.log("Ey there is an error");
                    alert("No tracking data for  competitor " + competitorNumber4  +" at the moment")

                    competitorTrackerEvent4.close();
                    setCompetitorTracker4([]);
                    setCompetitorNumber4(null);
                    clearInterval(eventInterval4);
                }
            },840000);

            competitorTrackerEvent4.onerror = () =>{
                console.log("Ey there is an error");
                alert("No tracking data for  competitor " + competitorNumber4  +" at the moment")

                competitorTrackerEvent4.close();
                setCompetitorTracker4([]);
                setCompetitorNumber4(null);
                clearInterval(eventInterval4);
            }
        }

        return ()=>{
            clearInterval(eventInterval4);
            if(!!competitorTrackerEvent4){
                competitorTrackerEvent4.close();
            }
        }
    },[competitorNumber4]);


    const updateCompetitorTracker1 = (trackingData) => {
        setCompetitorTracker1([trackingData]);
        console.log("competitorTracker1: " + competitorTracker1);
    }

    const updateCompetitorTracker2 = (trackingData) => {
        setCompetitorTracker2([trackingData]);
        console.log("competitorTracker2: " + competitorTracker2);
    }

    const updateCompetitorTracker3 = (trackingData) => {
        setCompetitorTracker3([trackingData]);
        console.log("competitorTracker3: " + competitorTracker3);
    }

    const updateCompetitorTracker4 = (trackingData) => {
        setCompetitorTracker4([trackingData]);
        console.log("competitorTracker4: " + competitorTracker4);
    }
    const goBackHandle = ()=>{

        history.goBack();
    }

    const getCompetitorGender = (competitor)=>{
        setCompetitorGender(competitor.gender) ;
        console.log(`Competitor Gender: ${competitorGender}`)
    }

    if (loading) return <LoadingSpinner/>


    return(
        <div style={{with: '100%', height:'100%'}}>
            <div >
                <TrackingMap
                    lineString = {lineString}
                    competitorTracker1={competitorTracker1}
                    competitorTracker2={competitorTracker2}
                    competitorTracker3={competitorTracker3}
                    competitorTracker4={competitorTracker4}
                    distanceObj={props.location.state.distanceObj}
                    distanceName={props.location.state.distanceName}
                    goBackHandle={goBackHandle}
                    distanceTrack={distanceTrack}
                    competitorGender1={competitorGender1}
                    competitorGender2={competitorGender2}
                    competitorGender3={competitorGender3}
                    competitorGender4={competitorGender4}
                    checkpoints={checkpoints}
                    competitors={competitors}
                    stopCompetitorTracking={stopCompetitorTracking}
                    trackCompetitor={trackCompetitor}
                />
            </div>
            <div >
                <ElevationProfileChart
                    distanceTrack={distanceTrack}
                    checkpoints={checkpoints}
                    distanceObj={props.location.state.distanceObj}
                    competitorTracker1={competitorTracker1}
                    competitorTracker2={competitorTracker2}
                    competitorTracker3={competitorTracker3}
                    competitorTracker4={competitorTracker4}
                />
            </div>
            {/*</div>*/}
            {/*</Container>*/}
        </div>
    )
}
export default CompetitorTrackerDashboard;







