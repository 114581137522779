import React, {useRef} from 'react';
import ReactDom from 'react-dom'
import VoucherContentToPrint from "../pages/registrations/VoucherContentToPrint";
import{useReactToPrint} from 'react-to-print'
import './RegistrationVoucher.css'
import upasportLogo from "../../images/upa-sport-5-text-to-path.svg";

const RegistrationVoucher = (props) => {

    const {showRegistrationVoucher, toggleShowVoucher, hideModalCardForm, registerData, distanceName} = props;

    const voucherToPrintRef = useRef(null);

    const handlePrint = useReactToPrint({
        // copyStyles: false,
        documentTitle: `inscripcion ${distanceName} ${registerData.firstName} ${registerData.lastName}`,
        content: () => voucherToPrintRef.current,
        onAfterPrint: () => hideModalCardForm()
    });


    if(!showRegistrationVoucher) return null;

    return ReactDom.createPortal(
        <div className='voucher-overlay'>
            <div  className='voucher-box'>
                <div className='voucher-content'>
                    <VoucherContentToPrint ref={voucherToPrintRef}
                                           registerData={registerData}
                                           distanceName={distanceName}
                    />

                </div>
                <div className='voucher-footer'>
                    <img src={upasportLogo}/>
                    <h4>Gracias {registerData.firstName}, tu inscripción ha sido confirmada</h4>
                    <button onClick={handlePrint}>
                        Imprimir comprobante
                    </button>
                </div>

            </div>
        </div>,
        document.getElementById('registration-voucher-portal')
    );
}

export default RegistrationVoucher;