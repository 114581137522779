import React  from "react";
import {useHistory} from "react-router";
import L, {DomUtil} from "leaflet";
import {useMap} from "react-leaflet";
import './SeekerMapBackButton.css'

const SeekerMapBackButton = () => {
    const history = useHistory();
    const map = useMap();

    L.Control.SeekerMapBackButton = L.Control.extend({

        onAdd: (map) => {

            const buttonRapper = DomUtil.create('div', 'button-rapper');


            const backButton = document.createElement('button');
            backButton.classList.add('back-button');
            backButton.textContent = '<  Regresar';
            backButton.addEventListener('click', ()=>history.push('/races'));

            buttonRapper.appendChild(backButton);

            return buttonRapper;

        },
        onRemove: (map) => {}
    });

    L.control.seekerMapBackButton = (opts) =>{
        return new L.Control.SeekerMapBackButton(opts);
    }

    L.control.seekerMapBackButton({position: 'bottomleft'}).addTo(map);


    return null;

}

export default SeekerMapBackButton