import {useMapEvents} from "react-leaflet";
import L, {DomUtil} from "leaflet";
import {useLeafletContext} from "@react-leaflet/core";
import {useEffect, useLayoutEffect} from "react";


const TrackingMapSearchBox = (props)=>{

    const {competitors, trackCompetitor} = props;

    let ulList,inputBox;

    useMapEvents({
        click() {
            if (inputBox.style.display==='inline-block') {
                inputBox.style.display = 'none'
                for (let i=0;i< ulList.childNodes.length; i++) {
                    ulList.childNodes[i].style.display = 'block'
                }
                ulList.style.display = 'none'
            }
        }
    });

    const context = useLeafletContext()

    L.Control.TrackingMapSearchBox = L.Control.extend({



        onAdd: (map) => {


            const toggleUl = () =>{
                if(ulList.style.display === 'none'){
                    ulList.style.display = 'block';
                    ulList.style.cursor = 'pointer'
                }else {
                    ulList.style.display = 'none'
                    ulList.style.cursor = 'none'
                }
            }

            let competitorsLi;
            const filterCompetitors = ()=>{

                let value = inputBox.value;
                competitorsLi = ulList.childNodes;

                for (let i=0;i< competitorsLi.length; i++){
                    let name = competitorsLi[i]
                    if (name.innerText.toLowerCase().indexOf(value.toLowerCase())> -1){
                        competitorsLi[i].style.display = 'block';
                    }else {
                        competitorsLi[i].style.display = 'none'
                    }
                }
            }



            const searchDiv = DomUtil.create("div", "map-search-box-div");
            L.DomEvent.disableClickPropagation(searchDiv)

            const searchButton = document.createElement('button')
            searchButton.classList.add('map-search-box-button')

            searchButton.onclick = ()=>{
                if(inputBox.style.display === 'none'){
                    inputBox.style.display = 'inline-block'
                    inputBox.value = ""
                }else {
                    inputBox.style.display = 'none'
                    for (let i=0;i< ulList.childNodes.length; i++){
                        ulList.childNodes[i].style.display = 'block'
                    }
                    ulList.style.display = 'none'
                }
            }

            const searchIcon = document.createElement('i')
            searchIcon.classList.add('fa','fa-search', 'search-icon')
            searchIcon.setAttribute('aria-hidden', 'true')

            const inputBoxContainer = document.createElement('div')
            inputBoxContainer.classList.add('input-box-container')

            inputBox = document.createElement('input');
            inputBox.classList.add('map-search-box-input')
            inputBox.style.display = 'inline-block';
            inputBox.setAttribute('type', 'text');
            inputBox.setAttribute('placeholder','Buscar competidor ....')
            inputBox.addEventListener('click', toggleUl)
            inputBox.addEventListener('keyup', filterCompetitors)


            ulList = document.createElement('ul')
            ulList.classList.add('map-search-ul')
            //
            // if(ulList.style.display === 'block'){
            //     ulList.style.display === 'block'
            // }

            competitors.forEach((competitor)=>{
                let liList = document.createElement('li');

                liList.innerText = competitor.firstName + " " + competitor.lastName + " " + competitor.competitorNumber;
                ulList.appendChild(liList);
            });

            searchButton.appendChild(searchIcon)
            searchDiv.appendChild(searchButton)
            searchDiv.appendChild(inputBoxContainer)
            inputBoxContainer.appendChild(inputBox)
            inputBoxContainer.appendChild(ulList)

            const onSelectionLi = (i) =>{

                inputBox.value = ulList.children[i].innerText;
                const monitoringCompetitorNumber = parseInt(inputBox.value.split(" ").pop());
                const gender = competitors.filter((competitor)=>competitor.competitorNumber===monitoringCompetitorNumber)[0].gender;

                trackCompetitor(monitoringCompetitorNumber,gender);
                ulList.style.display = 'none';
                // inputBox.style.display = 'none';
            }


            for (let i = 0; i< ulList.children.length; i++)
            {
                ulList.children[i].onclick = () =>{
                    return onSelectionLi(i);
                }
            }

            return searchDiv;
        },
        onRemove: (map) => {

        },
    });

    useEffect(()=>{
        L.control.TrackingMapSearchBox = (opts) => {
            return new L.Control.TrackingMapSearchBox(opts);
        }
        const container = context.layerContainer || context.map;
        const control = L.control.TrackingMapSearchBox({position: 'topleft'})
        container.addControl(control);

        return ()=>{
            container.removeControl(control);
        };
    },);

    return null;
}

export default TrackingMapSearchBox