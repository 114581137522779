import L from "leaflet";
import {Marker, Popup} from "react-leaflet";
import React, {useState} from "react";
import axios from "axios";
import CheckpointMarkerPopup from "../tracking/CheckpointMarkerPopup";

const CheckpointsMarkers = (props) => {

    const {REACT_APP_BASE_URL} = process.env;
    const {distance, trackingNumber} = props;

    const [numberOfCompetitorsChecked, setNumberOfCompetitorsChecked] = useState(null);
    const [checkpointTimestamps, setCheckpointTimestamps] = useState([]);
    const [loading, setLoading] = useState(true)

    const fetchFirstAndLastTimestampByCheckpoint = (distance, checkpointId) =>{

        axios.get(REACT_APP_BASE_URL + 'checkpoint-first-last-timestamps/' + distance + '/' + checkpointId)
            .then(response => {
                setCheckpointTimestamps(response.data);
                setLoading(false);

            }).catch(err=>{
            console.log(err);
        });

    }

    const fetchNumberOfCompetitorsChecked = (distanceName, checkpointId) =>{
        axios.get(REACT_APP_BASE_URL + "competitors-checked/"+ distanceName + "/" + checkpointId)
            .then(response => {
                setNumberOfCompetitorsChecked(response.data);


            }).catch(err=>{
            console.log(err);
        });
    };

    const getCheckpointIcon = (_iconSize) =>{
        return L.icon({
            iconUrl: "/icons/clock-512.png",
            iconSize : [_iconSize]
        })
    }

    return (
        distance && distance.checkpoints
            ? distance.checkpoints
                .filter(checkpoint => checkpoint.checkpointType !== ("PreCheck"))
                .map(checkpoint =>
                    <Marker
                        key={checkpoint.checkpointId}
                        position={[checkpoint.checkpointLatitude, checkpoint.checkpointLongitude]}
                        icon={getCheckpointIcon(20)}
                        eventHandlers={{
                            click:(e)=>{
                                fetchNumberOfCompetitorsChecked(distance.distanceName, checkpoint.checkpointId);
                                fetchFirstAndLastTimestampByCheckpoint(distance.distanceName, checkpoint.checkpointId);
                            }}}
                    >
                        <Popup>
                            <CheckpointMarkerPopup
                                checkpoint={checkpoint}
                                numberOfCompetitors={trackingNumber}
                                numberOfCompetitorsChecked={numberOfCompetitorsChecked}
                                checkpointTimestamps={checkpointTimestamps}
                                loading={loading}/>
                        </Popup>
                    </Marker>
                )
            : null
    )

}

export default CheckpointsMarkers;