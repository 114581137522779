import React, {useState, useEffect} from "react";
import axios from "axios";
import * as XLSX from "xlsx"
import './ResultsTable.css';
import LoadingSpinner from "../../layout/LoadingSpinner";
import { parseISO, format } from 'date-fns'
import es from 'date-fns/locale/es'
import CheckpointsModal from "../../modals/CheckpointsModal";
import ResultsHeader from "./ResultsHeader";



const ResultsTable = (props) => {
    const {REACT_APP_BASE_URL} = process.env;
    const {route, branch} = props;

    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [q, setQ] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [coords, setCoords] = useState({});
    const [resultIndex, setResultIndex] = useState(null);
    const [selectedAgeCategory, setSelectedAgeCategory] = useState('General');
    const [selectedNominalCategory, setSelectedNominalCategory] = useState('')
    const [selectedMixSegment, setSelectedMixSegment] = useState('')

    useEffect(() => {
        if (route.justResultBySegment && route.justResultBySegment === true && selectedMixSegment === '') {
            setSelectedMixSegment('mix-segments')
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        console.log('Selected Segment: ' + selectedMixSegment)

        let baseUrl = REACT_APP_BASE_URL.endsWith('/') ? REACT_APP_BASE_URL.slice(0, -1) : REACT_APP_BASE_URL;

        if (selectedAgeCategory !== 'General') {
            const AgeCategoryId = getAgeCategoryIdByName(selectedAgeCategory, route.ageCategories);

            if (selectedMixSegment === 'mix-segments') {
                baseUrl += `/mix-segments-results/${route.distanceName}/age-category/${AgeCategoryId}`;
            } else if (selectedMixSegment !== '') {
                baseUrl += `/segment-results/${route.distanceName}/${selectedMixSegment}/age-category/${AgeCategoryId}`;
            } else {
                baseUrl += `/age-category-results/${route.distanceName}/${AgeCategoryId}`;
            }

            // Add logic for gender if necessary
        } else if (selectedMixSegment === 'mix-segments') {
            baseUrl += `/mix-segments-results/${route.distanceName}`;
            if (selectedNominalCategory !== '') {
                baseUrl += `/nominal-category/${selectedNominalCategory}`;
            }
        } else if (selectedMixSegment !== '') {
            baseUrl += `/segment-results/${route.distanceName}/${selectedMixSegment}`;
            if (selectedNominalCategory !== '') {
                baseUrl += `/nominal-category/${selectedNominalCategory}`;
            }
        } else if (selectedNominalCategory !== '') {
            baseUrl += `/nominal-category-results/${route.distanceName}/${selectedNominalCategory}`;
        } else {
            baseUrl += `/results/${route.distanceName}`;
        }

        const finalUrl = baseUrl + `/${branch}`;
        console.log(`Result URL ${finalUrl}`);

        axios.get(finalUrl)
            .then(res => {
                console.log(res)
                setResults(res.data)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => setLoading(false));
    }, [selectedAgeCategory, selectedNominalCategory, selectedMixSegment]);


    const handleAgeCategorySelectionChange = (e) => {
        setSelectedAgeCategory(e.target.value);

    };
    const handleNominalCategorySelectionChange = (e) => {
        setSelectedNominalCategory(e.target.value)
    };

    const handleSegmentSelectionChange = (e) => {
        setSelectedMixSegment(e.target.value);
    };

    const showModal = () => {
        setIsOpen(true);
    };
    const hideModal = () => {
        setIsOpen(false);
    };

    const search = (rows) => {

        return rows.filter(row =>
            row.fullName.toLowerCase().indexOf(q) > -1 ||
            row.competitorNumber.toString().toLowerCase().indexOf(q) > -1
        );
    }

    const formatDate = (isoDate) => {
        if (isoDate) {
            let date = parseISO(isoDate.toString());
            let dayName = format(date, "ccc", {locale: es});
            let hour = format(date, "HH:mm:ss.SSS", {locale: es});
            return `${dayName} ${hour}`;
        }
        return "";
    }
    const getAgeCategoryIdByName = (ageCategoryName, ageCategories) => {
        if (ageCategories && ageCategories.length > 0) {
            const filtered = ageCategories
                .filter(ageCategory => ageCategory.name === ageCategoryName)
                .map(ageCategory => ageCategory.categoryId);
            if (filtered.length > 0) {
                return filtered[0];
            }
        }
        return null;
    }
    const getBookName = (gender) => {
        if (gender === 'MALE') return route.distanceName + ' Masculino.xlsx'
        if (gender === 'FEMALE') return route.distanceName + ' Femenino.xlsx'
        else return route.distanceName + ' General.xlsx'
    }

    const getSegment = (selectedMixSegment) => {
        if (selectedMixSegment === '') {
            return ''
        }else if (selectedMixSegment === 'mix-segments') {
            return route.mixSegment.name
        }else {
            return route.checkpointSegments.filter(segment => segment.segmentId == selectedMixSegment)
                .map(segment => segment.name)[0]

        }
    }

    const getAgeCategory = (selectedAgeCategory) => {

        return selectedAgeCategory === 'General'?'':selectedAgeCategory
    }

    const getTitle = () => {
        return 'Resultados '+ route.distanceName.toString().slice(0, -3)
    }

    const  getSubtitle = (gender) => {
        const kmStr = route.distanceName.toString().slice(-3)
        if (gender === 'MALE') return kmStr + ' Masculino ' + getAgeCategory(selectedAgeCategory) + ' ' + getSegment(selectedMixSegment)
        if (gender === 'FEMALE') return kmStr + ' Femenino ' + getAgeCategory(selectedAgeCategory) + ' ' + getSegment(selectedMixSegment)
        else return kmStr + ' General' + ' ' + getSegment(selectedMixSegment)
    }

    const getPositionStr = (position) => {
        if (position == 1) return 'Primer Lugar'
        if (position == 2) return 'Segundo Lugar'
        if (position == 3) return 'Tercer Lugar'
        if (position == 4) return 'Cuarto Lugar'
        if (position == 5) return 'Quinto Lugar'
        if (position == 6) return 'Sexto Lugar'
        if (position == 7) return 'Septimo Lugar'
        if (position == 8) return 'Octavo Lugar'
        if (position == 9) return 'Noveno Lugar'
        if (position == 10) return 'Decimo Lugar'
        else return position
    }

    const rows = results.map(result => (

        {
            POSICION: getPositionStr(result.position),
            "No.": result.competitorNumber,
            NOMBRE: result.fullName,
            // TIEMPOS: result.status === 'Finalizado'?result.competitionTime:result.status
            TIEMPOS: (route.justResultBySegment && route.justResultBySegment === true)?
                (result.status === 'Finalizado' || result.status === 'No ha llegado')?result.competitionTime:result.status
                :result.status === 'Finalizado'?result.competitionTime:result.status
        }

    ));

    const title = [{
        POSICION: getTitle(),
        "No.": "",
        NOMBRE: "",
        TIEMPOS: ""
    }];

    const blank = [{
        POSICION:"",
        "No.": "",
        NOMBRE: "",
        TIEMPOS: ""
    }];

    const subtitle = [{
        POSICION: getSubtitle(branch),
        "No.": "",
        NOMBRE: "",
        TIEMPOS: ""
    }];

    const finalData = [...title, ...blank, ...subtitle, ...rows]
    const handledOnExport = () => {
        const workbook = XLSX.utils.book_new()
        const worksheet = XLSX.utils.json_to_sheet(finalData,  { skipHeader: true })

        worksheet["!merges"] = [
            XLSX.utils.decode_range("A1:D1"),
            XLSX.utils.decode_range("A2:D2"),
            XLSX.utils.decode_range("A3:D3")
        ]

        XLSX.utils.book_append_sheet(workbook, worksheet, "sheet1")

        worksheet["!cols"] = [ { wch: 15} , {wch: 5}, {wch: 20},{wch: 12}, ]; // set columns widths

        XLSX.writeFile(workbook, getBookName(branch))
    }

    const getTableTitle  =  () => {
        return (route.justResultBySegment && route.justResultBySegment === true)
            ?route.distanceName + ' ' +  getSegment(selectedMixSegment):route.distanceName
    }

    if (loading) return <LoadingSpinner/>

    return (
        <div className='table-results-container'>
            <div>
                {/*<ResultsHeader route={route} selectedMixSegment={selectedMixSegment}/>*/}
                <input type='text' placeholder='Buscar competidor...' value={q} onChange={e => setQ(e.target.value)}/>
                <button className='handled-export-button' onClick={handledOnExport}>Exportar a Excel</button>
                {/*<h4>{getTableTitle()}</h4>*/}

                {route.ageCategories && route.ageCategories.length > 0 ? (
                    <select value={selectedAgeCategory} onChange={handleAgeCategorySelectionChange}>
                        <option value="General">Categoria por Edades</option>
                        {route.ageCategories.map((ageCategory) => (
                            <option key={ageCategory.categoryId} value={ageCategory.name}>
                                {ageCategory.name}
                            </option>
                        ))}
                    </select>
                ) : null}
                {route.nominalCategories && route.nominalCategories.length > 0 ? (
                    <select value={selectedNominalCategory} onChange={handleNominalCategorySelectionChange}>
                        <option value=''>Categoria Nominal</option>
                        {route.nominalCategories.map((nominalCategory) => (
                            <option key={nominalCategory} value={nominalCategory}>
                                {nominalCategory}
                            </option>
                        ))}
                    </select>
                ) : null}

                {route.checkpointSegments && route.checkpointSegments.length > 0 ? (
                    <select value={selectedMixSegment} onChange={handleSegmentSelectionChange}>
                        {route.justResultBySegment && route.justResultBySegment === true?null:(<option value="">Seleccione un Segmento</option>)}
                        {route.checkpointSegments.map((segment) => (
                            <option key={segment.segmentId} value={segment.segmentId}>
                                {segment.name}
                            </option>
                        ))}
                        {route.mixSegment ? (
                            <option
                                value={'mix-segments'}>
                                {route.mixSegment.name}
                            </option>
                        ) : null}
                    </select>
                ) : null}
            </div>
            <table>
                <thead>
                <tr>
                    <th className='th-competitor'>Competidor</th>
                    <th className='th-status'>Status</th>
                    <th className='th-start-time'>Comienzo</th>
                    <th className='th-finish-time'>Finalización</th>
                    <th className='th-competition-time'>Tiempo</th>
                    <th className='th-competition-time-diff'>Ref. 1er lugar</th>
                    <th className='th-checkpoints'>Control</th>
                    <th className='th-position'>Posición</th>
                </tr>
                </thead>
                <tbody>
                {
                    search(results).map(
                        result =>
                            <tr key={result.id}>
                                <td className='td-name'>
                                    <div>{result.competitorNumber}</div>
                                    <div>{result.fullName}</div>
                                </td>
                                <td className='td-status'>{result.status}</td>
                                <td className='td-start-time'>{formatDate(result.startHour)}</td>
                                <td className='td-finish-time'>{formatDate(result.finishHour)}</td>
                                <td className='td-competition-time'>{result.competitionTime}</td>
                                <td className='td-competition-time-diff'>{result.diffFirstPlace}</td>
                                <td className='td-checkpoints'>
                                    <button onClick={e => {
                                        if (window.screen.width > 650) {
                                            const rect = e.target.getBoundingClientRect();
                                            setCoords({
                                                left: rect.x + rect.width - 300,
                                                top: rect.y + window.scrollY - 130
                                            });
                                        }
                                        setResultIndex(results.indexOf((result)));
                                        showModal();
                                    }}>Detalles
                                    </button>
                                </td>
                                <td className='td-position'>{result.position}</td>
                            </tr>
                    )
                }
                </tbody>
            </table>
            {
                isOpen ?
                    <CheckpointsModal
                        coords={coords}
                        isOpen={isOpen}
                        hideModal={hideModal}
                        competitorFullName={results[resultIndex].fullName}
                        competitorNumber={results[resultIndex].competitorNumber}
                        checkedCheckpoints={results[resultIndex].checkedCheckpoints}
                        starTime={results[resultIndex].startHour}
                    /> : null
            }
        </div>
    )

}
export default ResultsTable