import React from "react";
import './CheckpointMarkerPopup.css'
import ClockIcon from '../../../static/icons/clock-512.png'

const CheckpointMarkerPopup = (props) => {

    const {checkpoint, numberOfCompetitors, numberOfCompetitorsChecked, checkpointTimestamps, loading} = props;

    return(
            <ul className='checkpoint-list'>
            <img src={ClockIcon} alt=" "/>
                <h5>{checkpoint.checkpointName} {checkpoint.checkpointDescription}</h5>
                <li>
                    <span className='list-item'>KM</span>
                    <span className='data-item'>{(checkpoint.checkpointDistance/1000).toFixed(2)} km</span>
                </li>
                <li>
                    <span className='list-item'>Elevación</span>
                    <span className='data-item'>{checkpoint.checkpointElevation.toFixed(0)} m</span>
                </li>
                {/*<li>*/}
                {/*    <span className='list-item'>Tiempo límite</span>*/}
                {/*    <span className='data-item'>{checkpoint.minutesLimitsCheckpoint} min</span>*/}
                {/*</li>*/}
                <li>
                    <span className='list-item'>Competidores chequeados</span>
                    <span className='data-item'>{numberOfCompetitorsChecked} de {numberOfCompetitors}</span>
                </li>
                <li>
                    <span className='list-item'>Competidores por chequear</span>
                    <span className='data-item'>{numberOfCompetitors-numberOfCompetitorsChecked} de {numberOfCompetitors}</span>
                </li>
                <li>
                    <span className='list-item'>Primer competidor</span>
                    <span className='data-item'>{loading?"n/a":checkpointTimestamps[0]?checkpointTimestamps[0].split("T")[1]:"n/a"}</span>
                </li>
                <li>
                    <span className='list-item'>Último competidor</span>
                    <span className='data-item'>{loading?"n/a":checkpointTimestamps[1]?checkpointTimestamps[1].split("T")[1]:"n/a"}</span>
                </li>
            </ul>

    )
}

export default CheckpointMarkerPopup;