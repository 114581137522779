// commonFunctions.js

import L from "leaflet";

export const getCompetitorMapIcon = (iconSize, number, gender, scaleFactor, fontSize) => {
    const iconColor = gender === "MALE" ? '00AC78' : 'B164FF';  // Color basado en el género
    const svgHtml = `<svg xmlns="http://www.w3.org/2000/svg" width="${iconSize * scaleFactor}" height="${iconSize * scaleFactor}" viewBox="0 0 24 24" fill="#${iconColor}">
        <circle cx="12" cy="12" r="10" />
        <text x="12" y="16" font-family="Arial" font-size="${fontSize}" fill="#ffffff" text-anchor="middle">${number}</text>
    </svg>`;

    return L.divIcon({
        html: svgHtml, // Aquí usamos el SVG generado
        className: 'custom-div-icon',
        iconSize: [iconSize * scaleFactor, iconSize * scaleFactor],
        iconAnchor: [iconSize * scaleFactor / 2, iconSize * scaleFactor],
        popupAnchor: [0, -(iconSize * scaleFactor / 2)]
    });
};

// more functions here